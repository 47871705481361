import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Select, Spin, Alert, Empty, Table, Checkbox, Collapse } from 'antd';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import mailflag from '../../img/MailFlag.png';
import mailflagred from '../../img/MailFlagRed.png';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import { useMediaQuery } from 'react-responsive';
import { SM_SCREEN } from '../../services/SpaService';

export default function RequestArchiver() {
    const { Panel } = Collapse;

    const [state, setState] = useState({
        requests: [],
        dataLoaded: false,
        requestEditMode: 'Add',
        selectedRequestIdx: null,
        selectedRequest: {},
        formFields: {},
        selectedRequestId: null,
        selectedRequests: []
    });

    const isDesktop = useMediaQuery({ minWidth: 1900 });
    const isMobile = useMediaQuery({ maxWidth: SM_SCREEN });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [agentsListData, setAgentsListData] = useState([]);

    const [contractorsListData, setContractorsListData] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([]);
    const [currentPageSize, setCurrentPageSize] = useState([]);

    const { register, getValues, setValue, control, watch } = useForm();

    const [jobsListData, setJobsListData] = useState([]);

    useEffect(() => {
        //const subscription = watch((value, { name, type }) => {
        //    if (name === 'formFields.requestAgent' || name === 'formFields.requestStatus' || name === 'formFields.contractor' || name === 'formFields.job') {
        //        //console.log('about to call GetFilteredRequests...');
        //        //console.log('search criteria changed...');
        //        //GetFilteredRequests();
        //    }
        //});
        setCurrentPage(1);
        setCurrentPageSize(10);

        setValue('formFields', {});
        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.contractor', null)
        setValue('formFields.pastCompletionDate', false)
        setValue('formFields.notAwarded120', false)
        setValue('formFields.allGrantedHoursBilled', false)
        setValue('formFields.hideHrsRemaining', true)
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);
        setValue('formFields.job', null);

        setState({
            ...state
        });

        InitializeRequestManager();

        /*return () => { subscription.unsubscribe(); };*/
    }, []);

    function InitializeRequestManager() { 
        Axios.get(`/api/InitializeRequestManager`, {
            params: {
                contractorID: getValues().formFields.contractor,
                requestStatus: getValues().formFields.requestStatus,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: 'Active'
            }
        }).then(response => {
            let requestsList = response.data.requests;
            let agentsList = response.data.agents;
            let contractorsList = response.data.contractors;
            let jobsList = response.data.jobs;

            setAgentsListData(agentsList);
            setContractorsListData(contractorsList);
            setJobsListData(jobsList);

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function GetFilteredRequests() {
        let specialArchiveGet = '';

        if (getValues().formFields.pastCompletionDate === true) {
            specialArchiveGet = 'pastCompletionDate';
        }
        if (getValues().formFields.notAwarded120 === true) {
            specialArchiveGet = 'notAwarded120';
        }
        if (getValues().formFields.allGrantedHoursBilled === true) {
            specialArchiveGet = 'allGrantedHoursBilled';
        }

        Axios.get(`/api/GetFilteredRequests`, {
            params: {
                contractorID: getValues().formFields.contractor,
                requestStatus: getValues().formFields.requestStatus,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: 'Active',
                specialArchiveGet: specialArchiveGet,
                fromDate: getValues().formFields.fromDate,
                toDate: getValues().formFields.toDate
            }
        }).then(response => {
            let requestsList = response.data;

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function awardedFormatter(row) {
        if (row.mrRequest.requestStatus === 'Awarded') {
            if (row.mrRequest.dollarsPerHour < 10) {
                return (
                    <span><img alt="awardedflag" className="ms-2" src={mailflag} style={{ height: '15px' }} /></span>
                );
            }
            else {
                return (
                    <span><img alt="awardedflag" className="ms-2" src={mailflagred} style={{ height: '15px' }} /></span>
                );
            }
        }
        else {
            return (
                <span></span>
            );
        }
    }

    function handleSelectRequest(event, cell, row, idx) {
        const target = event.target;
        const value = target.checked;

        let myselectedRequests = state.selectedRequests;

        if (value) {
            myselectedRequests.push(row);
        }
        else {
            const index = myselectedRequests.indexOf(row);
            if (index > -1) {
                myselectedRequests.splice(index, 1);
            }
        }

        setState({
            ...state,
            selectedRequests: myselectedRequests
        });
    }

    //function isColumnHidden() {
    //    if (getValues().formFields?.hideHrsRemaining === true) {
    //        return true;
    //    }
    //    else {
    //        return false;
    //    }
    //}

    //function isSelectCheckBoxHidden(cell, row) {
    //    console.log(row);

    //    //if (row.hrsPending === 0) {
    //    //    return false;
    //    //}
    //    //else {
    //    //    return true;
    //    //}
    //}

    function hrsPendingFormatter(row) {
        if (row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            if (row.hrsPending === 0) {
                return (
                    <div>{row.hrsPending}</div>
                );
            }
            else {
                return (
                    <div style={{color: 'red'}}>{row.hrsPending}</div>
                );
            }
        }
        else {
            return (
                <div>N/A</div>
            );
        }
    }

    const columns = [
        {
            title: 'requestID', dataIndex: ['mrRequest', 'requestID'], key: 'requestID', hidden: true
        }, {
            dataIndex: 'selectForArchiving',
            title: '',
            key: 'selectForArchiving',
            width: 50,
            render: (cell, row, idx) => {
                return (
                    <>
                        <Checkbox id="chkSelect" onChange={(event) => handleSelectRequest(event, cell, row, idx)} defaultChecked={row.selectForArchiving} />
                    </>
                )
            },
            align: 'center'
        }, {
            title: '',
            key: 'awardedFlag',
            width: 50,
            render: (row) => awardedFormatter(row),
            align: 'center'
        }, {
            dataIndex: ['mrRequest', 'requestDate'],
            title: 'Request Date',
            key: 'requestDate',
            width: 140,
            render: (requestDate) => {
                return (
                    <div>
                        {requestDate === null ? '' : Moment(dateWithNoTimezone(requestDate)).format("L")}
                    </div>
                );
            },
            sorter: (a, b) => a.mrRequest?.requestDate?.localeCompare(b.mrRequest?.requestDate),
            responsive: ['sm'],
        }, {
            dataIndex: ['mrRequest', 'anticipatedCompletionDate'],
            title: 'Est Completion Date',
            key: 'anticipatedCompletionDate',
            width: 140,
            render: (anticipatedCompletionDate) => {
                return (
                    <div>
                        {anticipatedCompletionDate === null ? '' : Moment(dateWithNoTimezone(anticipatedCompletionDate)).format("L")}
                    </div>
                );
            },
            sorter: (a, b) => a.mrRequest?.requestDate?.localeCompare(b.mrRequest?.requestDate),
            responsive: ['xxl'],
        }, {
            dataIndex: ['mrRequest', 'requestStatus'],
            title: 'Status',
            key: 'requestStatus',
            sorter: (a, b) => a.mrRequest.requestStatus.localeCompare(b.mrRequest.requestStatus),
            responsive: ['sm'],
        }, {
            dataIndex: 'contractorName',
            title: 'Contractor',
            key: 'contractorName',
            sorter: (a, b) => a.contractorName.localeCompare(b.contractorName),
            responsive: ['lg'],
        }, {
            dataIndex: ['mrRequest', 'projectName'],
            title: 'Project Details',
            key: 'projectName',
            render: (cell, row, idx) => (
                <div>{row.mrRequest?.projectName}<br />Type of Hours: {row.mrRequest?.typeOfHours}</div>
            ),
            sorter: (a, b) => a.mrRequest.projectName.localeCompare(b.mrRequest.projectName)
        }, {
            dataIndex: 'jobName',
            title: 'Job Details',
            key: 'jobName',
            sorter: (a, b) => a.jobName.localeCompare(b.jobName),
            responsive: ['lg'],
        }, {
            title: 'Hrs Granted',
            key: 'hrsGranted',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed' ? <div>{row.mrRequest.grantedHours}</div> : <div>N/A</div>,
            align: 'center',
            responsive: ['xxl'],
        }, {
            title: 'Hrs Paid',
            key: 'hrsPaid',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed' ? <div>{row.totalHoursPaid}</div> : <div>N/A</div>,
            align: 'center',
            responsive: ['xxl'],
        }, {
            title: 'Hrs Remaining',
            key: 'hrsRemaining',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed' ? <div>{row.hrsRemaining}</div> : <div>N/A</div>,
            align: 'center',
            responsive: ['xxl'],
        }, {
            title: 'Submitted Hrs Pending Payment',
            key: 'hrsPending',
            width: 120,
            render: (row) => hrsPendingFormatter(row),
            align: 'center',
            responsive: ['xxl'],
        }, {
            dataIndex: 'agentName',
            title: 'Agent',
            key: 'agentName',
            sorter: (a, b) => a.agentName.localeCompare(b.agentName),
            responsive: ['xxl'],
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    async function updateTableDisplay(requests, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        //if (idxToDirectTo !== null) {
        //    const page = idxToDirectTo > 0 ? Math.ceil(idxToDirectTo / tableControl.sizePerPage) : 1;

        //    await setTableControl({ ...tableControl, page });
        //} else {
        //    idxToDirectTo = 0
        //}

        //console.log(requests[idxToDirectTo]);

        //setState({
        //    ...state,
        //    requests,
        //    selectedRequest: requests[idxToDirectTo],
        //    dataLoaded: true
        //});
    }

    function onContractorChange(value) {
        setValue('formFields.contractor', value)

        setState({
            ...state
        });
    }

    function onAgentChange(value) {
        setValue('formFields.requestAgent', value)

        setState({
            ...state
        });
    }

    function handleSearch() {
        if (CheckForValidDateRange() === false) {
            toast.error("Invalid Report Date Range Specified");
            return;
        }

        if (getValues().formFields?.notAwarded120 === true) {
            setValue('formFields.hideHrsRemaining', true);
        }
        else {
            setValue('formFields.hideHrsRemaining', false);
        }

        GetFilteredRequests();
    }

    function CheckForValidDateRange() {
        //No dates entered so considered OK
        if (getValues().formFields.fromDate === null && getValues().formFields.toDate === null) {
            return true;
        }
        else if (getValues().formFields.fromDate <= getValues().formFields.toDate) {
            return true;
        }
        else {
            //this indicates either one of the date fields is null OR the From date is > To date
            return false;
        }
    }

    function handleReset() {
        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.contractor', null)
        setValue('formFields.pastCompletionDate', false)
        setValue('formFields.notAwarded120', false)
        setValue('formFields.allGrantedHoursBilled', false)
        setValue('formFields.hideHrsRemaining', true);
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);
        setValue('formFields.job', null);

        GetFilteredRequests();
    }

    function onJobChange(value) {
        setValue('formFields.job', value)

        setState({
            ...state
        });
    }

    function handleNotAwarded120Clicked() {
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.pastCompletionDate', false);
        setValue('formFields.allGrantedHoursBilled', false);

        setState({
            ...state
        });
    }

    function handlePastCompletionDateClicked() {
        setValue('formFields.requestStatus', 'Awarded');
        setValue('formFields.notAwarded120', false);
        setValue('formFields.allGrantedHoursBilled', false);

        setState({
            ...state
        });
    }

    function handleAllGrantedHoursBilledClicked() {
        setValue('formFields.requestStatus', 'Awarded');
        setValue('formFields.pastCompletionDate', false);
        setValue('formFields.notAwarded120', false);

        setState({
            ...state
        });
    }

    function archiveSelectedRequests() {
        var itemsSelected = 0;
        let myConfirmTitle = "";

        let myselectedRequests = state.selectedRequests;

        //console.log(myselectedRequests.length);

        //state.selectedRequests.forEach((request, idx) => {
        //    if (request.selectForArchiving === true) {
        //        itemsSelected += 1;
        //    }
        //});

        if (myselectedRequests.length === 0) {
            toast.error("No requests were selected for archiving");

            return;
        }

        if (myselectedRequests.length === 1) {
            myConfirmTitle = "You are about to Archive 1 request";
        }
        else {
            myConfirmTitle = "You are about to Archive " + myselectedRequests.length.toString() + " requests";
        }

        confirm({
            title: myConfirmTitle,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                archiveRequests(myselectedRequests.length);

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function archiveRequests(itemsSelected) {
        var itemsArchived = 0;

        await state.selectedRequests.forEach((request, idx) => {
            //Archive Job if no other requests are tied to it
            Axios.get(`/api/GetOtherRequestsForJob`, {
                params: {
                    jobs_ID: request.mrRequest.jobs_ID,
                    requestID: request.mrRequest.requestID
                }
            }).then(response => {
                if (response.data.length === 0) {
                    //Archive Job if no other active requests are tied to it
                    let postdata = {
                        jobs_ID: request.mrRequest.jobs_ID
                    }

                    Axios.post(`/api/ArchiveJob`, postdata
                    ).then(response => {

                    }).catch(error => {
                        console.log(error);
                    });
                }
            }).catch(error => {
                console.log(error);
            });

            //Archive selected request and create history record
            let postdata = {
                RequestID: request.mrRequest.requestID,
                Username: userInfo.fullName,
                archived: 1
            }

            Axios.post(`/api/ToggleRequestArchive`, postdata
            ).then(response => {
                itemsArchived += 1;

            }).catch(error => {
                console.log(error);
            });
        });

        GetFilteredRequests();

        if (itemsSelected === 1) {
            toast.success("1 request was successfully archived");
        }
        else {
            toast.success(itemsSelected.toString() + " requests were successfully archived");
        }
    }

    function updatePageState(page, pageSize) {
        var index;

        //this requecks the boxes on all the pages
        let myRequests = state.requests;
        let myselectedRequests = state.selectedRequests;

        for (var i = 0; i < myRequests.length; i++) {
            index = myselectedRequests.indexOf(myRequests[i]);
            if (index > -1) {
                myRequests[i].selectForArchiving = true;
            }
        }      

        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
            requests: myRequests
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            ARCHIVE REQUESTS
                        </h2>

                        {/*<button type="button" className="btn btn-submit margin-0-10" onClick={handleAddCustomer}>*/}
                        {/*    Add Contractor*/}
                        {/*</button>*/}
                    </div>
                    <Fragment>
                        <br />
                        <Collapse defaultActiveKey={!isMobile ? ['1'] : []}>
                            <Panel header="Search Filters" key="1">
                        <div className="row">
                            <div className="form-group col-lg-2 col-12">
                                <label className="form-label">Agent</label><br />
                                <Select
                                    {...register("formFields.requestAgent")}
                                    size="large" placeholder="All"
                                    className="form-control-custom"
                                    showSearch
                                    allowClear={false}
                                    optionFilterProp="children"
                                    onChange={onAgentChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.requestAgent}
                                >
                                    <option value="All">All</option>
                                    {agentsListData?.length > 0 && agentsListData?.map((agent, idx) => <option index={idx} key={agent.users_ID} value={agent.users_ID}>{agent.firstName} {agent.lastName}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-2 col-12">
                                <label className="form-label">Select Status</label>
                                <select className="form-control-custom" {...register("formFields.requestStatus")}>
                                    <option>All</option>
                                    <option>Awarded</option>
                                    <option>Completed</option>
                                    <option>Denied</option>
                                    <option>Granted</option>
                                    <option>Lost</option>
                                    <option>Pending</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Select Contractor <span className="color-pink">*</span></label><br />
                                <Select
                                    {...register("formFields.contractor")}
                                    size="large" placeholder="Select Contractor"
                                    className="form-control-custom"
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onContractorSearch}
                                    onChange={onContractorChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.contractor}
                                >
                                    {/*                    <Option value="jack">Jack</Option>*/}
                                    {/*<option value='' />*/}
                                    {contractorsListData?.length > 0 && contractorsListData?.map((contractor, idx) => <option index={idx} key={contractor.contractors_ID} value={contractor.contractors_ID}>{contractor.name}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-5 col-12">
                                <label className="form-label">Select Job</label><br />
                                <Select
                                    {...register("formFields.job")}
                                    size="large" placeholder="Select Job"
                                    className="form-control-custom"
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onJobSearch}
                                    onChange={onJobChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.job}
                                >
                                    {/*<option value='' />*/}
                                    {jobsListData?.length > 0 && jobsListData?.map((job, idx) => <option index={idx} key={job.jobs_ID} value={job.jobs_ID}>{job.jobName}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Request From Date</label>
                                <Controller
                                    name="formFields.fromDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                            onChange={date => setValue('formFields.fromDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label className="form-label">Request To Date</label>
                                <Controller
                                    name="formFields.toDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                            onChange={date => setValue('formFields.toDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="form-group col-lg-12">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input {...register("formFields.notAwarded120")} type="checkbox" className="custom-control-input" onClick={handleNotAwarded120Clicked} />
                                    <label className="custom-control-label">&nbsp;Get Requests More Than 120 Days From Bid Date That Have Not Been Awarded</label>
                                </div>
                            </div>
                            <div className="form-group col-lg-12">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input {...register("formFields.pastCompletionDate")} type="checkbox" className="custom-control-input" onClick={handlePastCompletionDateClicked} />
                                    <label className="custom-control-label">&nbsp;Get Awarded Requests Where Estimated Completion Date Has Passed And Still Have Hours Remaining</label>
                                </div>
                            </div>
                            <div className="form-group col-lg-12">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input {...register("formFields.allGrantedHoursBilled")} type="checkbox" className="custom-control-input" onClick={handleAllGrantedHoursBilledClicked} />
                                    <label className="custom-control-label">&nbsp;Get Awarded Requests Where All Granted Hours Have Been Billed</label>
                                </div>
                            </div>
                            
                        </div>
                        <p></p>
                        <div className="row">
                            <div className="form-group mt-3">
                                <button type="button" className="btn btn-submit margin-0-10" onClick={handleSearch}>
                                    Search
                                </button>&nbsp;&nbsp;&nbsp;
                                <button type="button" className="btn btn-outline-primary margin-0-10" onClick={handleReset}>
                                    Reset
                                </button>
                                <button className="btn btn-submit float-right ms-3" onClick={archiveSelectedRequests}>Archive Selected Requests</button>
                            </div>
                                </div>

                            </Panel>
                        </Collapse>
                        <hr style={{ border: "1px solid" }} />
                        <div>
                            <Spin spinning={!state.dataLoaded}>
                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                    <Alert
                                        message="Loading Market Recovery Requests"
                                        description="Please stand by while we retrieve requests matching your selection criteria"
                                        type="info"
                                    />
                                </div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {state.requests.length === 0 ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                <span>
                                                    No Market Recovery Requests found matching your search criteria
                                                </span>
                                            } />
                                            :
                                            <div>
                                                <img alt="mailflag" className="ms-2" src={mailflag} style={{ height: '15px' }} />&nbsp;Indicates the request was Awarded<br />
                                                <img alt="mailflagred" className="ms-2" src={mailflagred} style={{ height: '15px' }} />&nbsp;Indicates the request was Awarded at $10 per hour or more<br />

                                                <Table className="custom-ant-selection"
                                                    rowKey={(record) => record.mrRequest.requestID}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    bordered
                                                    size="small"
                                                    dataSource={state.requests}
                                                    style={{ whiteSpace: 'pre' }}
                                                    columns={columns}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={!isMobile ? { left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" } : { fontWeight: 'bold' }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        ),
                                                        onChange: (page, pageSize) => {
                                                            updatePageState(page, pageSize);
                                                        },
                                                        current: currentPage,
                                                        pageSize: currentPageSize
                                                    }}
                                                    expandable={!isDesktop && {
                                                        expandedRowRender: (record) => (
                                                            <ExpandedRow record={record} />
                                                        ),
                                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                                    }}
                                                    onRow={(record, index) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.mrRequest.requestID];
                                                                setSelectedRowKeys([...selRows]);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </Spin>
                        </div>
                    </Fragment>
                </div>
            </div>
        </div>
    );
}

const ExpandedRow = ({ record }) =>
(
    <div className="row">
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Request Date: </label>
            <span>{record.mrRequest?.requestDate}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Anticipated Completion Date: </label>
            <span>{record.mrRequest?.anticipatedCompletionDate}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Request Status: </label>
            <span>{record.mrRequest?.requestStatus}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Contractor Name: </label>
            <span>{record.contractorName}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Job Name: </label>
            <span>{record.jobName}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Hours Granted: </label>
            <span>{record.hrsGranted}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Hours Paid: </label>
            <span>{record.hrsPaid}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Hours Remaining: </label>
            <span>{record.hrsRemaining}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Hours Pending: </label>
            <span>{record.hrsPending}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Agent Name: </label>
            <span>{record.agentName}</span>
        </div>
    </div>
);