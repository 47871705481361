import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Select, Empty, Spin, Alert, Table, Collapse, message } from 'antd';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import MRRequestDetailsSlider from './MRRequestDetailsSlider';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import mailflag from '../../img/MailFlag.png';
import mailflagred from '../../img/MailFlagRed.png';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import { TbFileReport } from "react-icons/tb";
import { useMediaQuery } from 'react-responsive';
import { SM_SCREEN, XL_SCREEN } from '../../services/SpaService';

export default function RequestManager() {
    const { Panel } = Collapse;

    const [state, setState] = useState({
        requests: [],
        dataLoaded: false,
        showRequestDetailsSlider: false,
        requestEditMode: 'Add',
        selectedRequestIdx: null,
        selectedRequest: {},
        formFields: {},
        selectedRequestId: null
    });

    const isDesktop = useMediaQuery({ minWidth: XL_SCREEN });
    const isMobile = useMediaQuery({ maxWidth: SM_SCREEN });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [agentsListData, setAgentsListData] = useState([]);

    const [contractorsListData, setContractorsListData] = useState([]);

    const [jobsListData, setJobsListData] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState([]);
    const [currentPageSize, setCurrentPageSize] = useState([]);
    const { Option } = Select;
    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        //const subscription = watch((value, { name, type }) => {
        //    if (name === 'formFields.requestAgent' || name === 'formFields.requestStatus' || name === 'formFields.contractor' || name === 'formFields.job') {
        //        //console.log('about to call GetFilteredRequests...');
        //        //console.log('search criteria changed...');
        //        //GetFilteredRequests();
        //    }
        //});

        setCurrentPage(1);
        setCurrentPageSize(10);

        setValue('formFields', {});
        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.contractor', null);
        setValue('formFields.job', null);
        setValue('formFields.archived', 'Active');
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);

        setState({
            ...state
        });

        InitializeRequestManager();

        /*return () => { subscription.unsubscribe(); };*/
    }, []);

    useEffect(() => {
    }, [state.requests]);

    function InitializeRequestManager() {
        Axios.get(`/api/InitializeRequestManager`, {
            params: {
                contractorID: getValues().formFields.contractor,
                requestStatus: getValues().formFields.requestStatus,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: getValues().formFields.archived,
                fromDate: null,
                toDate: null
            }
        }).then(response => {
            let requestsList = response.data.requests;
            let agentsList = response.data.agents;
            let contractorsList = response.data.contractors;
            let jobsList = response.data.jobs;

            setAgentsListData(agentsList);
            setContractorsListData(contractorsList);
            setJobsListData(jobsList);

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function GetFilteredRequests() {
        Axios.get(`/api/GetFilteredRequests`, {
            params: {
                contractorID: getValues().formFields.contractor,
                requestStatus: getValues().formFields.requestStatus,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: getValues().formFields.archived,
                fromDate: getDateStr(getValues().formFields.fromDate),
                toDate: getDateStr(getValues().formFields.toDate)
            }
        }).then(response => {
            let requestsList = response.data;

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true,
                showRequestDetailsSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const getDateStr = (date) => {

        if (date === null) { return ""; }

        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        if (month.length == 1) { month = '0' + month; }
        if (day.length == 1) { day = '0' + day; }

        return year + month + day;
    }

    function handleContractorStatusChange(e) {
        setState({
            ...state,
            selectedContractorStatus: e.target.value
        });
    }

    //function handleDelete(contractor) {
    //    confirm({
    //        title: "You are about to permanently delete this Contractor",
    //        content: "Are you sure you would like to proceed?",
    //        buttons: ["Yes", "No"]
    //    }, (buttonPressed) => {
    //        if (buttonPressed === 'Yes') {

    //            //this uses the ignore field in the AccountsModel to pass in the selected filterType to be used when returning the new list of Accounts
    //            contractor.contractorStatus = state.selectedContractorStatus;

    //            Axios.post(`/api/DeleteContractor`, contractor
    //            ).then(response => {
    //                if (response.data) {
    //                    toast.success("Contractor has been deleted");

    //                    setState({
    //                        ...state,
    //                        contractors: response.data,
    //                        dataLoaded: true
    //                    });
    //                }
    //            }).catch(error => {
    //                console.log(error);
    //            });

    //            return 0;
    //        } else {
    //            return 0;
    //        }
    //    });
    //}

    function awardedFormatter(row) {
        if (row.mrRequest.requestStatus === 'Awarded') {
            if (row.mrRequest.dollarsPerHour < 10) {
                return (
                    <span><img alt="awardedflag" className="ms-2" src={mailflag} style={{ height: '15px' }} /></span>
                );
            }
            else {
                return (
                    <span><img alt="awardedflag" className="ms-2" src={mailflagred} style={{ height: '15px' }} /></span>
                );
            }
        }
        else {
            return (
                <span></span>
            );
        }
    }

    const columns = [
        {
            title: 'requestID', dataIndex: ['mrRequest', 'requestID'], key: 'requestID', hidden: true
        },
        {
            title: '',
            key: 'awardedFlag',
            width: 50,
            render: (row) => awardedFormatter(row),
            align: 'center'
        }, {
            dataIndex: ['mrRequest', 'requestDate'],
            title: 'Request Date',
            key: 'requestDate',
            width: 140,
            render: (requestDate) => {
                return (
                    <div>
                        {requestDate === null ? '' : Moment(dateWithNoTimezone(requestDate)).format("L")}
                    </div>
                );
            },
            sorter: (a, b) => a.mrRequest?.requestDate?.localeCompare(b.mrRequest?.requestDate),
            responsive: ['sm'],
        }, {
            dataIndex: ['mrRequest', 'requestStatus'],
            title: 'Status',
            key: 'requestStatus',
            sorter: (a, b) => a.mrRequest.requestStatus.localeCompare(b.mrRequest.requestStatus),
            responsive: ['sm'],
        }, {
            dataIndex: 'contractorName',
            title: 'Contractor',
            key: 'contractorName',
            sorter: (a, b) => a.contractorName.localeCompare(b.contractorName),
            responsive: ['lg'],
        }, {
            dataIndex: ['mrRequest', 'projectName'],
            title: 'Project Details',
            key: 'projectName',
            render: (cell, row, idx) => (
                <div>{row.mrRequest?.projectName}<br />Type of Hours: {row.mrRequest?.typeOfHours}</div>
            ),
            sorter: (a, b) => a.mrRequest.projectName.localeCompare(b.mrRequest.projectName)
        }, {
            dataIndex: 'jobName',
            title: 'Job Details',
            key: 'jobName',
            sorter: (a, b) => a.jobName.localeCompare(b.jobName),
            responsive: ['lg'],
        }, {
            dataIndex: 'agentName',
            title: 'Agent',
            key: 'agentName',
            sorter: (a, b) => a.agentName.localeCompare(b.agentName),
            responsive: ['xxl'],
        }, {
            title: 'Hrs Granted',
            key: 'hrsGranted',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed' ? <div>{row.mrRequest.grantedHours}</div> : <div>N/A</div>,
            align: 'center',
            responsive: ['xxl'],
        }, {
            title: 'Hrs Paid',
            key: 'hrsPaid',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed' ? <div>{row.totalHoursPaid}</div> : <div>N/A</div>,
            align: 'center',
            responsive: ['xxl'],
        }, {
            title: 'Hrs Remaining',
            key: 'hrsRemaining',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed' ? <div>{row.hrsRemaining}</div> : <div>N/A</div>,
            align: 'center',
            responsive: ['xxl'],
        }, {
            title: 'Archived',
            key: 'archived',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.archived ? <div><i id="arch" className="fa fa-check ml-3 hover" /><br /><button id="unarchive" type="button" class="btn btn-link" onClick={(e) => handleUnarchiveRequest(row, idx)}>Unarchive</button>
            </div> : <div></div>,
            align: 'center',
            responsive: ['xxl'],
        }, {
            title: 'Request Details',
            key: 'requestDetails',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => LoadRequestDetails(e, record)}><i id="requestDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center',
            responsive: ['xxl'],
        }, {
            title: 'Hrs Summary',
            key: 'hrsSummary',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed' ? <span className="hover" onClick={(e) => DownloadHoursSummaryReport(row.mrRequest.requestID, row.mrRequest.projectName, row.mrRequest.typeOfHours)}><TbFileReport id="hrsSummary" className="fa-2x hover" /></span> : <div></div>,
            align: 'center',
            responsive: ['xxl'],
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function LoadRequestDetails(e, record) {
        Axios.get(`/api/GetRequestById`, {
            params: {
                requestID: record.mrRequest.requestID
            }
        }).then(response => {
            setState({
                ...state,
                selectedRequest: response.data,
                selectedRequestId: record.mrRequest.requestID,
                showRequestDetailsSlider: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleUnarchiveRequest(row, idx) {
        confirm({
            title: "You are about to unarchive this request",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                //const target = e.target;
                //const value = target.value;

                var newList = [...state.requests];
                //var itemToUpdate = newList[idx];
                var itemToUpdate = row;

                var newMrRequest = { ...itemToUpdate.mrRequest };
                newMrRequest.archived = 0;
                itemToUpdate.mrRequest = newMrRequest;
                newList[idx] = itemToUpdate;

                let postdata = {
                    RequestID: itemToUpdate.mrRequest.requestID,
                    archived: 0
                }

                //set selected request to 'Lost'
                Axios.post(`/api/ToggleRequestArchive`, postdata
                ).then(response => {

                }).catch(error => {
                    toast.error("There was a problem setting requests to 'Lost'");
                });

                setState({
                    ...state,
                    requests: updateObjectInArray(newList, idx, itemToUpdate, 'archived'),
                    dataLoaded: true
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function updateObjectInArray(array, idx, itemToUpdate, propertyChanged) {
        return array.map((item, index) => {
            if (index !== idx) {
                // This isn't the item we care about - keep it as-is
                return item
            }

            // Otherwise, this is the one we want - return an updated value
            return {
                ...itemToUpdate,
                [propertyChanged]: itemToUpdate[propertyChanged]
            }
        })
    }

    function DownloadHoursSummaryReport(requestID, projectName, typeOfHours) {
        let myFileName = "";

        myFileName = "Hours_Summary_For_Request_Report";

        const key = "loading";

        let postdata = {
            requestID: requestID,
            projectName: projectName,
            typeOfHours: typeOfHours
        }

        message.loading({
            content: 'Report generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/DownloadHoursSummaryForRequestReport`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    async function updateTableDisplay(requests, idxToDirectTo = null) {
        var myPageSize = 10;
        var myPageIndex = 1;

        await setState({ ...state, dataLoaded: false });

        for (let i = 0; i < requests.length; i++) {
            if (requests[i].mrRequest.requestID === requests[idxToDirectTo].mrRequest.requestID) {
                myPageIndex = Math.floor(i / myPageSize) + 1
                break;
            }
        }

        updatePageState(myPageIndex, myPageSize);

        //now set the selected Contractor
        let selRows = [requests[idxToDirectTo].mrRequest.requestID];
        setSelectedRowKeys([...selRows]);

        setState({
            ...state,
            requests,
            selectedRequest: requests[idxToDirectTo],
            dataLoaded: true,
            showRequestDetailsSlider: false
        });
    }

    function onContractorChange(value) {
        setValue('formFields.contractor', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function onAgentChange(value) {
        setValue('formFields.requestAgent', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function onJobChange(value) {
        setValue('formFields.job', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function handleSearch() {
        if (CheckForValidDateRange() === false) {
            toast.error("Invalid Report Date Range Specified");
            return;
        }

        setState({
            ...state,
            dataLoaded: false
        });

        GetFilteredRequests();
    }

    function CheckForValidDateRange() {
        //No dates entered so considered OK
        if (getValues().formFields.fromDate === null && getValues().formFields.toDate === null) {
            return true;
        }
        else if (getValues().formFields.fromDate <= getValues().formFields.toDate) {
            return true;
        }
        else {
            //this indicates either one of the date fields is null OR the From date is > To date
            return false;
        }
    }

    function handleReset() {
        setState({
            ...state,
            dataLoaded: false
        });

        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.contractor', null);
        setValue('formFields.job', null);
        setValue('formFields.archived', 'Active');
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);

        GetFilteredRequests();
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            MANAGE CONTRACTOR REQUESTS
                        </h2>

                        {/*<button type="button" className="btn btn-submit margin-0-10" onClick={handleAddCustomer}>*/}
                        {/*    Add Contractor*/}
                        {/*</button>*/}
                    </div>
                    <Fragment>
                        <br />
                        <Collapse defaultActiveKey={!isMobile ? ['1'] : []}>
                            <Panel header="Search Filters" key="1">
                                <div className="row">
                                    <div className="form-group col-lg-2 col-12">
                                        <label className="form-label">Agent</label><br />
                                        <Select
                                            {...register("formFields.requestAgent")}
                                            size="large" placeholder="All"
                                            className="form-control-custom"
                                            showSearch
                                            allowClear={false}
                                            optionFilterProp="children"
                                            onChange={onAgentChange}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                            }
                                            value={getValues().formFields?.requestAgent}
                                        >
                                            <option value="All">All</option>
                                            {agentsListData?.length > 0 && agentsListData?.map((agent, idx) => <option index={idx} key={agent.users_ID} value={agent.users_ID}>{agent.firstName} {agent.lastName}</option>)}
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-2 col-12">
                                        <label className="form-label">Select Status</label>
                                        <select className="form-control-custom" {...register("formFields.requestStatus")}>
                                            <option>All</option>
                                            <option>Awarded</option>
                                            <option>Completed</option>
                                            <option>Denied</option>
                                            <option>Granted</option>
                                            <option>Lost</option>
                                            <option>Pending</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-3 col-12">
                                        <label className="form-label">Select Contractor <span className="color-pink">*</span></label><br />
                                        <Select
                                            {...register("formFields.contractor")}
                                            size="large" placeholder="Select Contractor"
                                            className="form-control-custom"
                                            showSearch
                                            allowClear={true}
                                            optionFilterProp="children"
                                            //onSearch={onContractorSearch}
                                            onChange={onContractorChange}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                            }
                                            value={getValues().formFields?.contractor}
                                        >
                                            {/*                    <Option value="jack">Jack</Option>*/}
                                            {/*<option value='' />*/}
                                            {contractorsListData?.length > 0 && contractorsListData?.map((contractor, idx) => <option index={idx} key={contractor.contractors_ID} value={contractor.contractors_ID}>{contractor.name}</option>)}
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-5 col-12">
                                        <label className="form-label">Select Job</label><br />
                                        <Select
                                            {...register("formFields.job")}
                                            size="large" placeholder="Select Job"
                                            className="form-control-custom"
                                            showSearch
                                            allowClear={true}
                                            optionFilterProp="children"
                                            //onSearch={onJobSearch}
                                            onChange={onJobChange}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                            }
                                            value={getValues().formFields?.job}
                                        >
                                            {/*<option value='' />*/}
                                            {jobsListData?.length > 0 && jobsListData?.map((job, idx) => <option index={idx} key={job.jobs_ID} value={job.jobs_ID}>{job.jobName}</option>)}
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-3 col-12">
                                        <label className="form-label">Request From Date</label>
                                        <Controller
                                            name="formFields.fromDate"
                                            control={control}
                                            setValue={setValue}
                                            defaultValue={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                            render={() =>
                                                <DatePicker
                                                    selected={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                                    onChange={date => setValue('formFields.fromDate', date)}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    } />
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-lg-3 col-12">
                                        <label className="form-label">Request To Date</label>
                                        <Controller
                                            name="formFields.toDate"
                                            control={control}
                                            setValue={setValue}
                                            defaultValue={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                            render={() =>
                                                <DatePicker
                                                    selected={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                                    onChange={date => setValue('formFields.toDate', date)}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    } />
                                            }
                                        />
                                    </div>
                                    {/*<div className="form-group col-lg-1">*/}
                                    {/*    <label className="form-label">Show Archived Requests</label>*/}
                                    {/*    <div className="form-control-custom no-border">*/}
                                    {/*        <div className="custom-control custom-checkbox custom-control-inline">*/}
                                    {/*            <input {...register("formFields.archived")} type="checkbox" className="custom-control-input" />*/}
                                    {/*            <label className="custom-control-label"></label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Archived Status</label>
                                        <select className="form-control-custom" {...register("formFields.archived")}>
                                            <option>Active</option>
                                            <option>Archived</option>
                                            <option>Both</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group mt-3">
                                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleSearch}>
                                            Search
                                        </button>&nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-outline-primary margin-0-10" onClick={handleReset}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </Panel>
                        </Collapse>
                        
                        <hr style={{ border: "1px solid" }} />
                        <div>
                            <Spin spinning={!state.dataLoaded}>
                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                    <Alert
                                        message="Loading Market Recovery Requests"
                                        description="Please stand by while we retrieve requests matching your selection criteria"
                                        type="info"
                                    />
                                </div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {state.requests.length === 0 ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                <span>
                                                    No Market Recovery Requests found matching your search criteria
                                                </span>
                                            } />
                                            :
                                        <div>
                                                <img alt="mailflag" className="ms-2" src={mailflag} style={{ height: '15px' }} />&nbsp;Indicates the request was Awarded<br />
                                                <img alt="mailflagred" className="ms-2" src={mailflagred} style={{ height: '15px' }} />&nbsp;Indicates the request was Awarded at $10 per hour or more<br />

                                                <Table className="custom-ant-selection"
                                                    rowKey={(record) => record.mrRequest.requestID}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    bordered
                                                    size="small"
                                                    dataSource={state.requests}
                                                    style={{ whiteSpace: 'pre' }}
                                                    columns={columns}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={!isMobile ? { left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" } : { fontWeight: 'bold' }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        ),
                                                        onChange: (page, pageSize) => {
                                                            updatePageState(page, pageSize);
                                                        },
                                                        current: currentPage,
                                                        pageSize: currentPageSize
                                                    }}
                                                    expandable={!isDesktop && {
                                                        expandedRowRender: (record) => (
                                                            <ExpandedRow record={record} />
                                                        ),
                                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                                    }}
                                                    onRow={(record, index) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.mrRequest.requestID];
                                                                setSelectedRowKeys([...selRows]);
                                                            },
                                                            onDoubleClick: (e) => {
                                                                LoadRequestDetails(e, record);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </Spin>
                        </div>
                        {state.showRequestDetailsSlider &&
                            <MRRequestDetailsSlider showPane={state.showRequestDetailsSlider} selectedRequestId={state.selectedRequestId} requests={state.requests} selectedContractorId={getValues().formFields?.contractor}
                                selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showRequestDetailsSlider: false })} />
                    }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}

const ExpandedRow = ({ record }) =>
(
    <div className="row">
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Request Date: </label>
            <span>{record.mrRequest?.requestDate}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Request Status: </label>
            <span>{record.mrRequest?.requestStatus}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Contractor Name: </label>
            <span>{record.contractorName}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Job Name: </label>
            <span>{record.jobName}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Agent Name: </label>
            <span>{record.agentName}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Hours Granted: </label>
            <span>{record.hrsGranted}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Hours Paid: </label>
            <span>{record.hrsPaid}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Hours Remaining: </label>
            <span>{record.hrsRemaining}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Archived: </label>
            <span>{record.archived}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Request Details: </label>
            <span>{record.requestDetails}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Hours Summary: </label>
            <span>{record.hrsSummary}</span>
        </div>
    </div>
);