import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import Globals from '../../config/globals';
import { dateWithNoTimezone } from '../Helpers/DateFormat';

export default function CVMarketRecoveryPolicySlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const [state, setState] = useState({
        showPane: props.showPane,
        formFields: {},
        dataLoaded: false
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        setState({
            ...state,
            dataLoaded: true,
            showPane: true
        });
    }, [props]);

    function hidePane() {
        setTimeout(() => props.hidePane(), 1000);

        setState({
            ...state,
            showPane: false
        });
    }

    function updateAgreeToTerms() {
        if (getValues().formFields.userInitials === '') {
            toast.error('You must enter your initials to accept the terms');
            return;
        }

        let postdata = {
            UserInitials: getValues().formFields.userInitials,
            Contacts_ID: userInfo.contacts_ID
        }

        Axios.post('/api/UpdateAgreeToTerms', postdata
        ).then(async response => {
            userInfo.agreedtoterms = response.data;

            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

            props.refreshUserInfo();

            hidePane();

            toast.success('Thank you. You are now clear to submit new requests');
        }).catch(error => {
            toast.error("Problem encountered Please contact Local 21 to report this problem.");
            console.log(error);
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Policy and Guidelines'
            onRequestClose={hidePane}
            width={'65%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <Fragment>
                            <div className="row">
                                <div className="form-group col-12 d-flex">
                                    <strong>Plumbers And Steamfitters Local 21 Job Targeting Program Market Recovery Fund - Policy and Guidelines</strong>
                                </div>
                            </div>

                            <div className="row">
                                {userInfo.agreedtoterms === null ?
                                    <Fragment>
                                        <div className="form-group col-4 d-flex"><label className="form-label" style={{ color: "red" }}>I agree to abide to the Memorandum Of Understanding</label></div>
                                        <div className="form-group col-2 d-flex">
                                            <input type="text" {...register("formFields.userInitials")} className="form-control-custom" maxlength="8" size="10" placeholder="Enter Initials & Click Agree" />
                                        </div>
                                        <div className="col-6"><button className="btn btn-outline-primary btn-sm" onClick={updateAgreeToTerms}>I Agree</button></div>
                                    </Fragment>
                                    :
                                    <Fragment>

                                    </Fragment>
                                }
                            </div>

                            {/*<div className="row">*/}
                            {/*    <div className="form-group col-4 d-flex"><label className="form-label" style={{ color: "red" }}>I agree to abide to the Memorandum Of Understanding</label></div>*/}
                            {/*    <div className="form-group col-2 d-flex">*/}
                            {/*        <input type="text" {...register("formFields.userInitials")} className="form-control-custom" maxlength="8" size="10" placeholder="Enter Initials & Click Agree" />*/}
                            {/*    </div>*/}
                            {/*    <div className="col-6">*/}
                            {/*    <button className="btn btn-outline-primary btn-sm" onClick={updateAgreeToTerms}>I Agree</button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <p></p>

                            <p>
                                <strong>1. Overview</strong><br />
                                The Local 21 Job Targeting Fund is to be used for signatory Contractors who are bidding against Contractors who are not signed to a Collective Bargaining Agreement with Plumbers and Steamfitters Local21 ("Local 21"). It is intended to be used for private work which falls under the scope of the current Local 21 Collective Bargaining Agreement. Subsidies will not be advanced for public work that is subject to prevailing wage laws.<br /><br />
                                The goals of the Program are to maintain and improve Plumbers and Steamfitters Local 21's market share of the residential and commercial plumbing industry. It also allows the Union to acquire direct market information on the amount of work being pursued by non-signatory Contractors.<br />
                            </p>

                            <p>
                                <strong>2. Administration</strong><br />
                                <ol type="a">
                                    <li>A committee composed of the Business Manager and three Business Agents, who shall be voting members, will administer the Program. The committee shall be obligated to make quarterly reports to the membership.</li>
                                    <li>The committee is authorized to expend reasonable funds from the Program to pay for expenses of the Program.</li>
                                    <li>The Program assets shall have a separate accounting, but shall be a part of the Union General Fund.</li>
                                </ol>
                            </p>

                            <p>
                                <strong>3. How the Program Works:</strong><br />
                                <ol type="a">
                                    <li>If a Contractor is aware of a job that he/she would like funded, he/she should contact the Business Manager of Local 21. The Business Manager will send the Contractor a Funding Request Form which must be completed a n d returned to the Union Office.</li>
                                    <li>The Program Committee will see that funded jobs a r e fairly distributed throughout the jurisdiction of Local 21.</li>
                                    <li>On the day before bids are due, the Business Manager will fax to all Contractors who submitted a request form for Program funds, the total amount of Program funds that will be allocated to the job based on the projected man hours to be worked. The subsidy provided will be on a per hour basis only for hours worked, up to the maximum originally set and determined by the Committee. Payment shall be for fringe benefits available through the Plumbers and Steamfitters Local Union 21 Fringe Benefit Funds. It shall not include payments for Union dues, wages or Industry Promotion funds.</li>
                                    <li>After notification of a successful bid, the Contractor must execute and deliver to the Program Administrator a signed Memorandum of Understanding. The Program Administrator will then send the Contractor a confi1mation letter detailing approval of the funding amount and notification to proceed with the project.</li>
                                    <li>Once the job starts, the Contractor must submit man-hour forms to the Program Administrator at the end of the month. These forms must reflect the hours each Local 21member has worked on the job and must also be verified by the Job Foreman and/or the Job Steward. The Contractor must notify the Program Administrator immediately upon the completion of the job. All hours subsidized must be worked by the members of the bargaining unit employed by the employer. If any portion of the bargaining unit work is subcontracted to an entity that does not have a collective bargaining agreement with Local 21, the Subsidy Agreement will be null and void and all subsidy payments made to the Contractor must be refunded to the Program.</li>
                                    <li>All man-hour forms must be current, and all other fringe benefit contributions must also be current for a Contractor to be eligible to receive a subsidy from the Program.</li>
                                    <li>Under no circumstances will the dollar amount originally agreed upon for the project be exceeded. The Contractor will be eligible only for the total hours and/or dollar amount approved by the Program Administrator. For example, if the job is targeted for 1,000 hours at $8.00 per hour, the total supplement will be $8,000.00. If the job is completed in only 800 hours, then the supplement will be 800 hours x $8.00 per hour for a total of $6,400.00. Should the job run over the 0riginal estimated hours, the maximum supplement will be $8,000.00, unless the Agreement is amended in writing and approved by the Committee.</li>
                                    <li>Negligence in complying with the above referenced policies will be just cause for discontinuance of Program Funding.</li>
                                    <li>Fifty percent (50%) of the manpower will come from the Union Hall.</li>
                                </ol>
                            </p>
                            <p>
                                <strong>MEMORANDUM OF UNDERSTANDING</strong><br />
                                This Memorandum of Understanding is by and between Plumbers and Steamfitters Local Union 21 (Local21) and you (The Contractor). It pertains specifically to the current
                                Collective Bargaining Agreement (hereinafter "CBA") signed by both parties. In entering into this Memorandum of Understanding, the Contractor and Local 21 agree to the following:
                                <ol type="1">
                                    <li>To abide by all terms and conditions of the current CBA, and if not currently a signatory, the Contractor agrees to become a signatory prior to the execution of the Memorandum.</li>
                                    <li>The Contractor agrees to remain current with all payments to Local 21, including fringe benefit funds, vacation funds, dues and any other payments as are called for in the CBA. Upon Written notification from the Fund Administrator of the Benefit Funds that a Contractor that is participating in the Job Targeting Program is delinquent in paying fringe payments from this program shall cease until such time as contributions to the fringe benefit fund are current.</li>
                                    <li>Weekly man-hour reports for any funded job reviewed and signed by both the Contractor representative and the Job Steward will be submitted to Local 21 on a twice per month basis, at the end of the second week and at the end of the month. Any disputes between the pa11ies as to the correct number of hours to be paid pursuant to the Memorandum shall be referred to the grievance procedure of the CBA.</li>
                                    <li>The funding commitment made in the Memorandum of Understanding pertains only to the job listed in the Funding Request Form hereof and may not be applied to any other work of the Contractor.</li>
                                    <li>The Contractor states without any qualification that it has no interest whatsoever whether directly or indirectly, in any type of venture which performs work covered by the CBA which is commonly referred to as "double breasting" and the Contractor agrees that it will not engage in such activity as long as it is party to the Program or the CBA.</li>
                                    <li>The Contractor states without any qualification that to the best of its knowledge and belief, no non-union Contractor who bids work in the work jurisdiction of Local 21 was solicited or induced by it or any other party to the CBA to bid on the job covered by the Memorandum of Understanding for the purpose of creating the need to utilize the Program for said job.</li>
                                    <li>All hours subsidized must be worked by members of the bargaining unit employed by the employer. If any portion of bargaining unit work is subcontracted to an entity that does not have a Collective Bargaining Agreement with local 21, the employer will forfeit all subsidies and may be excluded from further participation in the program.</li>
                                </ol>
                            </p>
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}