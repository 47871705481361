import React, { useEffect, useState } from 'react';
import logo from '../../img/Local21Logo.png';
//import logo from '../../img/local21_logo_sm.png';
import './navigation.css';

import { Popover, Select } from 'antd';
import Helmet from 'react-helmet';
import CustomSearch from './CustomSearch';

import { toast, confirm } from '@rickylandino/react-messages';

import $ from 'jquery';

import { useNavigate, useLocation } from "react-router-dom";
import { activeRoute } from '../../services/SpaService';
import { takeWhile } from 'rxjs/operators';

import MediaQuery from 'react-responsive'

import { FaUsers } from "react-icons/fa";
import { FaFileSignature } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import ReportsSlider from '../ReportingOutput/ReportsSlider';
import Axios from '../../config/axios';

export default function TopNav(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const navigate = useNavigate();

    const [state, setState] = useState({
        showReportsSlider: false,
        showCheckUtilitiesSlider: false
    });

    let location = useLocation();

    useEffect(() => {
        switch (location?.pathname) {
            case '/dashboard':
                $(".header-icon").removeClass("active");
                $("#dashboardBtn").addClass("active");
                break;
            case '/contractor-manager':
                $(".header-icon").removeClass("active");
                $("#contractorManagerBtn").addClass("active");
                break;
            case '/manage-contractor-requests':
            case '/mr-old-request':
            case '/archive-requests':
                $(".header-icon").removeClass("active");
                $("#marketRecoveryRequests").addClass("active");
                break;
            case '/job-manager':
                $(".header-icon").removeClass("active");
                $("#jobManagerBtn").addClass("active");
                break;
            case '/process-payments':
                $(".header-icon").removeClass("active");
                $("#processPaymentsBtn").addClass("active");
                break;
            case '/utilities':
            case '/data-cleanup':
                $(".header-icon").removeClass("active");
                $("#utilities").addClass("active");
                break;
            case '/user-manager':
            case '/role-template-manager':
            case '/menu-manager':
                $(".header-icon").removeClass("active");
                $("#admin").addClass("active");
                break;

            default:
                $(".header-icon").removeClass("active");
                break;
        }
    }, [location]);


    function handleNavigation(event) {
        event.preventDefault();

        $("#navbar-toggle").addClass("collapse");

        switch (event.target.id) {
            case 'dashboardBtn':
                $(".listHeader").removeClass("active open");
                navigate("/dashboard", { replace: true });
                break;
            case 'contractorManagerBtn':
                $(".listHeader").removeClass("active open");
                navigate("/contractor-manager", { replace: true });
                break;
            case 'myRequestsBtn':
                $(".listHeader").removeClass("active open");
                navigate("/manage-contractor-requests", { replace: true });
                break;
            case 'oldRequestsBtn':
                $(".listHeader").removeClass("active open");
                navigate("/mr-old-request", { replace: true });
                break;
            case 'archiverBtn':
                $(".listHeader").removeClass("active open");
                navigate("/archive-requests", { replace: true });
                break;
            case 'jobManagerBtn':
                $(".listHeader").removeClass("active open");
                navigate("/job-manager", { replace: true });
                break;
            case 'processPaymentsBtn':
                $(".listHeader").removeClass("active open");
                navigate("/process-payments", { replace: true });
                break;
            case 'checkUtilitiesBtn':
                $(".listHeader").removeClass("active open");
                navigate("/utilities", { replace: true });
                break;
            case 'dataUtilityBtn':
                $(".listHeader").removeClass("active open");
                navigate("/data-cleanup", { replace: true });
                break;
            case 'userManagerBtn':
                $(".listHeader").removeClass("active open");
                navigate("/user-manager", { replace: true });
                break;
            case 'roleTemplateManagerBtn':
                $(".listHeader").removeClass("active open");
                navigate("/role-template-manager", { replace: true });
                break;
            case 'menuManagerBtn':
                $(".listHeader").removeClass("active open");
                navigate("/menu-manager", { replace: true });
                break;
            case 'reportingBtn':
                setState({
                    ...state,
                    showReportsSlider: true
                });
                break;
            default:
                break;
        }

        $('.subTabs').parent('li').removeClass('active');
        $('#' + event.target.id).parent('li').addClass('active');
    }

    function handleLogout(event) {
        confirm({
            title: "You are about to logout",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                event.preventDefault();

                navigate("/", { replace: true });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleConvertPasswords() {
        let postdata = {};

        Axios.post(`/api/EncryptPasswords`, postdata
        ).then(response => {
            if (response.data === true) {
                toast.success("All user and contact passwords have been reset.");
            }
            else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });
    }

    const { Option } = Select;
    return (
        <nav className="navbar navbar-expand-xl navbar-light bg-header bd-navbar">
            <div className="navbar-brand hover">
                <img alt="Local777" id="dashboardBtn" onClick={handleNavigation} src={logo} style={{ height: '75px' }} />
                {/*<img alt="Local21" id="dashboardBtn" onClick={handleNavigation} src={logo} />*/}
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-toggle" aria-controls="navbar-toggle" aria-expanded="false" aria-label="Toggle navigation" onClick={() => $("#navbar-toggle").toggleClass("collapse") }>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbar-toggle">
                <ul className="navbar-nav mt-2 mt-lg-0 me-auto">
                    {userInfo.userType !== 'Contractor' &&
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <div>
                                <span className="header-icon mimicA top-nav-item" id="contractorManagerBtn" onClick={handleNavigation}>
                                    <MediaQuery minWidth={1570}>
                                        <FaUsers className="text-icon hideable fa-2x" id="contractorManagerBtn" onClick={handleNavigation} /><i className="fa-solid fa-helmet-safety fa-1x text-icon hideable" id="contractorManagerBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="contractorManagerBtn" onClick={handleNavigation}>Contractors</span>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1569}>
                                        <span className="top-nav-item-desc" id="contractorManagerBtn" onClick={handleNavigation}>Contractors</span>
                                    </MediaQuery>
                                </span>
                            </div>
                        </li>
                    }

                    {/*{userInfo.userType === 'Contractor' &&*/}
                    {/*    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">*/}
                    {/*        <div>*/}
                    {/*            <span className="header-icon mimicA top-nav-item" id="myProfileBtn" onClick={handleNavigation}>*/}
                    {/*                <MediaQuery minWidth={1570}>*/}
                    {/*                    <FaUsers className="text-icon hideable fa-2x" id="myProfileBtn" onClick={handleNavigation} /><i className="fa-solid fa-helmet-safety fa-1x text-icon hideable" id="myProfileBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="myProfileBtn" onClick={handleNavigation}>My Profile</span>*/}
                    {/*                </MediaQuery>*/}
                    {/*                <MediaQuery maxWidth={1569}>*/}
                    {/*                    <span className="top-nav-item-desc" id="myProfileBtn" onClick={handleNavigation}>My Profile</span>*/}
                    {/*                </MediaQuery>*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*}*/}

                    {userInfo.userType !== 'Contractor' &&
                        <li className="nav-item">
                            <div className="dropdown">
                                <span className="dropbtn header-icon mimicA top-nav-item" id="marketRecoveryRequests">
                                    <MediaQuery minWidth={1570}>
                                        <FaFileSignature className="fa-2x text-icon hideable" /> <span className="top-nav-item-desc">Requests</span>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1569}>
                                        <span className="top-nav-item-desc" id="" onClick={handleNavigation}>Requests</span>
                                    </MediaQuery>
                                </span>
                                <div className="dropdown-content">
                                    <div id="myRequestsBtn" onClick={handleNavigation}>
                                        <span className="subTabs waves-effect waves-themed active" id="myRequestsBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">
                                            Manage Contractor Requests
                                        </span>
                                    </div>

                                    <div id="oldRequestsBtn" onClick={handleNavigation}>
                                        <span className="subTabs waves-effect waves-themed active" id="oldRequestsBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">
                                            Add Old Request
                                        </span>
                                    </div>

                                    <div id="archiverBtn" onClick={handleNavigation}>
                                        <span className="subTabs waves-effect waves-themed active" id="archiverBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">
                                            Archive Requests
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    }
                    {/*{userInfo.userType === 'Contractor' &&*/}
                    {/*    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">*/}
                    {/*        <div>*/}
                    {/*            <span className="header-icon mimicA top-nav-item" id="myRequestsBtn" onClick={handleNavigation}>*/}
                    {/*                <MediaQuery minWidth={1570}>*/}
                    {/*                    <FaUsers className="text-icon hideable fa-2x" id="myRequestsBtn" onClick={handleNavigation} /><i className="fa-solid fa-helmet-safety fa-1x text-icon hideable" id="myRequestsBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="myRequestsBtn" onClick={handleNavigation}>My Requests</span>*/}
                    {/*                </MediaQuery>*/}
                    {/*                <MediaQuery maxWidth={1569}>*/}
                    {/*                    <span className="top-nav-item-desc" id="myRequestsBtn" onClick={handleNavigation}>My Requests</span>*/}
                    {/*                </MediaQuery>*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*}*/}

                    {userInfo.userType !== 'Contractor' &&
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <div>
                                <span className="header-icon mimicA top-nav-item" id="jobManagerBtn" onClick={handleNavigation}>
                                    <MediaQuery minWidth={1570}>
                                        <FaBuilding className="fa-1x text-icon hideable" id="jobManagerBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="jobManagerBtn" onClick={handleNavigation}>Jobs</span>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1569}>
                                        <span className="top-nav-item-desc" id="jobManagerBtn" onClick={handleNavigation}>Jobs</span>
                                    </MediaQuery>
                                </span>
                            </div>
                        </li>
                    }

                    {userInfo.userType !== 'Contractor' &&
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <div>
                                <span className="header-icon mimicA top-nav-item" id="processPaymentsBtn" onClick={handleNavigation}>
                                    <MediaQuery minWidth={1570}>
                                        <FaMoneyCheckAlt className="fa-1x text-icon hideable" id="processPaymentsBtn" onClick={handleNavigation} /> <span className="top-nav-item-desc" id="processPaymentsBtn" onClick={handleNavigation}>Payments</span>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1569}>
                                        <span className="top-nav-item-desc" id="processPaymentsBtn" onClick={handleNavigation}>Payments</span>
                                    </MediaQuery>
                                </span>
                            </div>
                        </li>
                    }

                    {userInfo.userType !== 'Contractor' &&
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                            <div>
                                <span className="header-icon mimicA top-nav-item" id="reportingBtn" onClick={handleNavigation}>
                                    <MediaQuery minWidth={1570}>
                                        <FaChartBar className="fa-1x text-icon hideable" /> <span className="top-nav-item-desc" id="reportingBtn" onClick={handleNavigation}>Reporting/Output</span>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1569}>
                                        <span className="top-nav-item-desc" id="" id="reportingBtn" onClick={handleNavigation}>Reporting</span>
                                    </MediaQuery>
                                </span>
                            </div>
                        </li>
                    }

                    <div className="search-div full-size ms-auto">
                        <div className="current-user">Welcome: {userInfo.fullName}</div>
                        {/*<CustomSearch />*/}
                    </div>

                    {userInfo.userType !== 'Contractor' &&
                        <li className="nav-item">
                            <div className="dropdown">
                                <span className="header-icon mimicA top-nav-item" id="utilities">
                                    <i className="fas fa-tools fa-1x text-icon"></i>
                                </span>
                                <div className="dropdown-content" style={{ right: '0' }}>
                                    <div id="userManagerBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <span className="subTabs waves-effect waves-themed active" id="userManagerBtn" onClick={handleNavigation}>
                                            Manage Users
                                        </span>
                                    </div>
                                    <div id="checkUtilitiesBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <span className="subTabs waves-effect waves-themed active" id="checkUtilitiesBtn" onClick={handleNavigation}>
                                            Check Utilities
                                        </span>
                                    </div>

                                    {userInfo.fullName === 'Dave Landino' &&
                                        <div id="convertPasswordsBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <span className="subTabs waves-effect waves-themed active" id="convertPasswordsBtn" onClick={handleConvertPasswords}>
                                                Convert Passwords
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </li>
                    }

                    {/*{userInfo.userType !== 'Contractor' &&*/}
                    {/*    <li className="nav-item">*/}
                    {/*        <div className="dropdown">*/}
                    {/*            <span className="header-icon mimicA top-nav-item" id="admin">*/}
                    {/*                <i className="fas fa-user-shield fa-1x text-icon"></i>*/}
                    {/*            </span>*/}
                    {/*            <div className="dropdown-content" style={{ right: '0' }}>*/}
                    {/*                <div id="userManagerBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">*/}
                    {/*                    <span className="subTabs waves-effect waves-themed active" id="userManagerBtn" onClick={handleNavigation}>*/}
                    {/*                        User Manager*/}
                    {/*                    </span>*/}
                    {/*                </div>*/}
                    {/*                <div id="roleTemplateManagerBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">*/}
                    {/*                    <span className="subTabs waves-effect waves-themed active" id="roleTemplateManagerBtn" onClick={handleNavigation}>*/}
                    {/*                        Role Template Manager*/}
                    {/*                    </span>*/}
                    {/*                </div>*/}
                    {/*                <div id="menuManagerBtn" onClick={handleNavigation} data-toggle="collapse" data-target=".navbar-collapse.show">*/}
                    {/*                    <span className="subTabs waves-effect waves-themed active" id="menuManagerBtn" onClick={handleNavigation}>*/}
                    {/*                        Menu Manager*/}
                    {/*                    </span>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*}*/}

                    <div className="seperator">
                    </div>

                    <div>
                        <span className="header-icon mimicA top-nav-item" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt logoutIcon" onClick={handleLogout} />
                        </span>
                    </div>
                </ul>
            </div>

            {state.showReportsSlider && <ReportsSlider showModal={state.showReportsSlider} hideModal={() => setState({ ...state, showReportsSlider: false })} />}

            {state.showCheckUtilitiesSlider && <ReportsSlider showModal={state.showCheckUtilitiesSlider} hideModal={() => setState({ ...state, showCheckUtilitiesSlider: false })} />}
            
        </nav>
    );
}