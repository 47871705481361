import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import Globals from '../../config/globals';
import { dateWithNoTimezone } from '../Helpers/DateFormat';

export default function CVSendAwardNotificationSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const [state, setState] = useState({
        showPane: props.showPane,
        formFields: {},
        dataLoaded: false
    });

    useEffect(() => {
        setValue("formFields", props.request);
        setValue("notificationDate", getFormattedDate(new Date()));
        setValue("formFields.awardDate", '');
        setValue("formFields.expectedStartDate", '');
        setValue("formFields.anticipatedCompletionDate", '');

        setState({
            ...state,
            dataLoaded: true,
            showPane: true
        });
    }, [props]);

    function getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        //return month + '/' + day + '/' + year;
        return year + '-' + month + '-' + day;
    }

    function getLocaltime() {
        return new Date().toLocaleTimeString([], { hour12: false });
    }

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });
    }

    function updateParentAwardStatus() {
        setTimeout(() => props.updateRequestStatus(), 1000);
        
        setState({
            ...state,
            showPane: false
        });
    }

    function screenDataOK() {
        let message = '';
        let myHrsTot = 0;

        if (getValues().formFields.awardDate === '') {
            message += '&bull;&nbsp;Award Date</br>';
        }
        if (getValues().formFields.expectedStartDate === '') {
            message += '&bull;&nbsp;Expected Start Date</br>';
        }
        if (getValues().formFields.anticipatedCompletionDate === '') {
            message += '&bull;&nbsp;Anticipated Completion Date</br>';
        }

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    //This function returns the date selected from the date/time picker and appends the local current time
    function getLocalDateTime(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let myDatePart = year + '-' + month + '-' + day;
        let myTimePart = new Date().toLocaleTimeString([], { hour12: false });

        const myDate = new Date(myDatePart + ' ' + myTimePart);
        const userTimezoneOffset = myDate.getTimezoneOffset() * 60000;

        return new Date(myDate.getTime() - userTimezoneOffset);
    }

    function onSubmitAwardNotification() {
        if (screenDataOK() === false) {
            return;
        }

        let userInfo = Globals.userInfo;

        let postdata = {
            RequestID: getValues().formFields.mrRequest.requestID,
            AwardDate: getLocalDateTime(getValues().formFields.awardDate),
            ExpectedStartDate: getLocalDateTime(getValues().formFields.expectedStartDate),
            AnticipatedCompletionDate: getLocalDateTime(getValues().formFields.anticipatedCompletionDate),
            Username: userInfo.fullName,
            EmailNotificationType: 'AwardNotification',
            ConcessionsMade: getValues().formFields.concessionsMade
        }

        Axios.post(`/api/ProcessAwardNotification`, postdata
        ).then(response => {
            if (response.data) {
                toast.success('Market Recovery Request successfully updated');

                updateParentAwardStatus();
            }
        }).catch(error => {
            console.log(error);
            toast.error('There was a problem updating Market Recovery Request');
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Market Recovery Award Notification'
            onRequestClose={hidePane}
            width={'65%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <Fragment>
                            <Card className="local777Card">
                                <Card.Header><span>Request Details</span></Card.Header>
                                <Card.Body>
                                    <form>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Date:</label>
                                        </div>
                                        <div className="col-lg-9">
                                            <span>{getValues().notificationDate}</span>
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Contractor Name:</label>
                                        </div>
                                        <div className="col-lg-9">
                                            <span>{getValues().formFields?.contractorName}</span>
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Project Name:</label>
                                        </div>
                                        <div className="col-lg-9">
                                            <span>{getValues().formFields?.mrRequest.projectName}</span>
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Job Site Address:</label>
                                        </div>
                                        <div className="col-lg-9">
                                            <span>{getValues().formFields?.mrRequest.jobsiteAddress}</span><br />
                                            <span>{getValues().formFields?.mrRequest.jobsiteCityState}</span>
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Requested Hours:</label>
                                        </div>
                                        <div className="col-lg-9">
                                            <span>{getValues().formFields?.mrRequest.requestedHours}</span>
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Granted Hours:</label>
                                        </div>
                                        <div className="col-lg-9">
                                            <span>{getValues().formFields?.mrRequest.grantedHours}</span>
                                        </div>

                                        <div className="col-lg-12">
                                            <hr style={{ border: "1px solid" }} />
                                        </div>

                                        <div className="col-lg-12">
                                            <label className="form-label mb-0">All fields below are required - estimate if you are not sure</label>
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Award Date <span className="color-pink">*</span></label>
                                            <Controller
                                                name="formFields.awardDate"
                                                control={control}
                                                setValue={setValue}
                                                defaultValue={getValues().formFields?.awardDate ? Moment(getValues().formFields.awardDate).toDate() : null}
                                                render={() =>
                                                    <DatePicker
                                                        selected={getValues().formFields?.awardDate ? Moment(getValues().formFields.awardDate).toDate() : null}
                                                        onChange={date => setValue('formFields.awardDate', date)}
                                                        className="form-control-custom"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <NumberFormat format="##/##/####" mask="_" />
                                                        } />
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Expected Start Date <span className="color-pink">*</span></label>
                                            <Controller
                                                name="formFields.expectedStartDate"
                                                control={control}
                                                setValue={setValue}
                                                defaultValue={getValues().formFields?.expectedStartDate ? Moment(getValues().formFields.expectedStartDate).toDate() : null}
                                                render={() =>
                                                    <DatePicker
                                                        selected={getValues().formFields?.expectedStartDate ? Moment(getValues().formFields.expectedStartDate).toDate() : null}
                                                        onChange={date => setValue('formFields.expectedStartDate', date)}
                                                        className="form-control-custom"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <NumberFormat format="##/##/####" mask="_" />
                                                        } />
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Anticipated Completion Date <span className="color-pink">*</span></label>
                                            <Controller
                                                name="formFields.anticipatedCompletionDate"
                                                control={control}
                                                setValue={setValue}
                                                defaultValue={getValues().formFields?.anticipatedCompletionDate ? Moment(getValues().formFields.anticipatedCompletionDate).toDate() : null}
                                                render={() =>
                                                    <DatePicker
                                                        selected={getValues().formFields?.anticipatedCompletionDate ? Moment(getValues().formFields.anticipatedCompletionDate).toDate() : null}
                                                        onChange={date => setValue('formFields.anticipatedCompletionDate', date)}
                                                        className="form-control-custom"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        customInput={
                                                            <NumberFormat format="##/##/####" mask="_" />
                                                        } />
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-lg-12">
                                            <label className="form-label">List any concessions you made to win the bid</label>
                                            <textarea name="concessions" rows="4" cols="50" {...register("formFields.concessionsMade")} className="form-control-custom" />
                                        </div>

                                            {/*<div className="form-group col-lg-4">*/}
                                            {/*    <label className="form-label">Job Name</label>*/}
                                            {/*    <input type="text" className="form-control-custom" {...register('projectName')} />*/}
                                            {/*</div>*/}
                                            {/*<div className="form-group col-lg-4">*/}
                                            {/*    <label className="form-label">Job Details</label>*/}
                                            {/*    <input type="text" className="form-control-custom" {...register('projectDetails')} />*/}
                                            {/*</div>*/}
                                            

                                            {/*<div className="form-group col-lg-4">*/}
                                            {/*    <label className="form-label">Address</label>*/}
                                            {/*    <input type="text" className="form-control-custom" {...register('jobsiteAddress')} />*/}
                                        {/*</div>*/}


                                            {/*<div className="form-group col-lg-3">*/}
                                            {/*    <label className="form-label">City</label>*/}
                                            {/*<select className="form-control-custom" {...register('jobsiteCity')} value={getValues().jobsiteCity} onChange={handleCityStateInputChange}>*/}
                                            {/*        <option value="">Select City</option>*/}
                                            {/*        {getValues().citiesList?.length > 0 && getValues().citiesList?.map((city, idx) => <option index={idx} key={city.cities_ID} value={city.city}>{city.city}</option>)}*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                            {/*<div className="form-group col-lg-2">*/}
                                            {/*    <label className="form-label">State</label>*/}
                                            {/*<select className="form-control-custom" {...register('jobsiteState')} value={getValues().jobsiteState} onChange={handleCityStateInputChange}>*/}
                                            {/*    <option value="">Select State</option>*/}
                                            {/*    <option value="CT">CT</option>*/}
                                            {/*</select>*/}
                                            {/*</div>*/}
                                            {/*<div className="form-group col-lg-3">*/}
                                            {/*    <label className="form-label">Zip</label>*/}
                                            {/*    <input type="text" className="form-control-custom" {...register('jobsiteZip')} />*/}
                                            {/*</div>*/}
                                            

                                            
                                        </div>                      
                                    </form>
                                </Card.Body>
                            </Card>
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit btn-sm" onClick={onSubmitAwardNotification}>Submit Award Notification</button>
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}