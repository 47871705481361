import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import CVRequestDetailsSlider from './CVRequestDetailsSlider';
import CVAddRequestSlider from './CVAddRequestSlider';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import { Empty, Spin, Alert, message, Table, Input } from 'antd';
import { Button } from "react-bootstrap";
import { TbFileReport } from "react-icons/tb";
import Moment from 'moment';
import CVMarketRecoveryPolicySlider from './CVMarketRecoveryPolicySlider';

export default function CVRequestsTab(props) {
    const [state, setState] = useState({
        selectedContractorId: '',
        requests: [],
        dataLoaded: false,
        showRequestDetailsSlider: false,
        showAddRequestSlider: false,
        requestEditMode: 'Add',
        selectedRequestIdx: null,
        selectedRequest: {},
        billingStatus: '',
        agentsList: [],
        formFields: {},
        selectedRequestId: null,
        showPolicySlider: false,
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [currentPage, setCurrentPage] = useState([1]);
    const [currentPageSize, setCurrentPageSize] = useState([10]);

    var userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            //console.log(value);
            //console.log(name);
            //console.log(type);

            //if (typeof (getValues().selectedContractorId) !== 'undefined') {
            //    loadContratorRequests();
            //}
        });

        return () => { subscription.unsubscribe(); };

    }, []);

    useEffect(() => {
        var alive = true;

        if (alive && props.selectedContractorId !== null) {
            LoadContratorRequests();
        }

        console.log('here');

    }, [props]);

    function LoadContratorRequests() { 
        Axios.get(`/api/GetContractorRequests`, {
            params: {
                contractorID: props.selectedContractorId
            }
        }).then(response => {
            let requestsList = response.data;

            setRequests(requestsList);
            setFilteredRequests(requestsList);

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true,
                showRequestDetailsSlider: false,
                showAddRequestSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    //function editFormatter(cell, row) {
    //    return (
    //        <i id="editRequest" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
    //    );
    //}

    //function hrsSummaryFormatter(cell, row) {
    //    if (row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
    //        return (
    //            <TbFileReport id="hrsSummary" className="fa-2x hover" />
    //        );
    //    }
    //    else {
    //        return (
    //            <div></div>
    //        );
    //    }
    //}

    //function detailsFormatter(cell, row) {
    //    return (
    //        <div>{row.mrRequest.projectName}<br />Type of Hours: {row.mrRequest.typeOfHours}</div>
    //    );
    //}

    //function dateFormatter(cell, row) {
    //    var mydate = Moment(dateWithNoTimezone(row.mrRequest.requestDate)).format("L");
    //    return (
    //        <div>{mydate}</div>
    //    );
    //}

    //const columns = [{
    //    dataField: 'mrRequest.requestID',
    //    text: 'requestID',
    //    hidden: true
    //}, {
    //    dataField: 'mrRequest.requestDate',
    //    text: 'Request Date',
    //    formatter: dateFormatter,
    //    sort: true,
    //    headerStyle: { width: '120px', textAlign: 'center' }
    //}, {
    //    dataField: 'mrRequest.requestStatus',
    //    text: 'Status',
    //    sort: true,
    //    headerStyle: { width: '100px', textAlign: 'center' }
    //}, {
    //    dataField: 'mrRequest.projectName',
    //    text: 'Project Name',
    //    sort: true
    //}, {
    //    dataField: 'mrRequest.typeOfHours',
    //    text: 'Type of Hours',
    //    sort: true
    //}, {
    //    dataField: 'dum1',
    //    isDummyField: true,
    //    text: 'Request Details',
    //    formatter: editFormatter,
    //    style: { textAlign: 'center' },
    //    headerStyle: { width: '130px' }
    //}, {
    //    dataField: 'dum2',
    //    isDummyField: true,
    //    text: 'Hours Summary',
    //    formatter: hrsSummaryFormatter,
    //    style: { textAlign: 'center' },
    //    headerStyle: { width: '130px' }
    //}];

    const columns = [
        {
            title: 'requestID', dataIndex: ['mrRequest', 'requestID'], key: 'requestID', hidden: true
        }, {
            dataIndex: ['mrRequest', 'requestDate'],
            title: 'Request Date',
            key: 'requestDate',
            width: 140,
            render: (requestDate) => {
                return (
                    <div>
                        {requestDate === null ? '' : Moment(dateWithNoTimezone(requestDate)).format("L")}
                    </div>
                );
            },
            sorter: (a, b) => a.mrRequest?.requestDate?.localeCompare(b.mrRequest?.requestDate)
        }, {
            dataIndex: ['mrRequest', 'requestStatus'],
            title: 'Status',
            key: 'requestStatus',
            sorter: (a, b) => a.mrRequest.requestStatus.localeCompare(b.mrRequest.requestStatus)
        }, {
            dataIndex: ['mrRequest', 'projectName'],
            title: 'Project Details',
            key: 'projectName',
            render: (cell, row, idx) => (
                <div>{row.mrRequest?.projectName}<br />Type of Hours: {row.mrRequest?.typeOfHours}</div>
            ),
            sorter: (a, b) => a.mrRequest.projectName.localeCompare(b.mrRequest.projectName)
        }, {
            dataIndex: ['mrRequest', 'projectName'],
            title: 'Project Details',
            key: 'projectName',
            render: (cell, row, idx) => (
                <div>{row.mrRequest?.projectName}<br />Type of Hours: {row.mrRequest?.typeOfHours}</div>
            ),
            sorter: (a, b) => a.mrRequest.projectName.localeCompare(b.mrRequest.projectName)
        }, {
            title: 'Request Details',
            key: 'requestDetails',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => LoadRequestDetails(e, record)}><i id="requestDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Hrs Summary',
            key: 'hrsSummary',
            width: 120,
            render: (cell, row, idx) => row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed' ? <span className="hover" onClick={(e) => DownloadHoursSummaryReport(row.mrRequest.requestID, row.mrRequest.projectName, row.mrRequest.typeOfHours)}><TbFileReport id="hrsSummary" className="fa-2x hover" /></span> : <div></div>,
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);

        setState({
            ...state,
            showRequestDetailsSlider: false,
            showPolicySlider: false
        });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    //const rowEvents = {
    //    onClick: (e, row, rowIndex) => {
    //        if (e.target.tagName === 'I' && e.target.id === 'editRequest') {
    //            LoadRequestDetails(row.mrRequest.requestID, rowIndex)
    //        }
    //        else if (e.target.tagName === 'svg' && e.target.id === 'hrsSummary') {
    //            DownloadHoursSummaryReport(row.mrRequest.requestID, row.mrRequest.projectName, row.mrRequest.typeOfHours);
    //        }
    //    },
    //    onDoubleClick: (e, row, rowIndex) => {
    //        //toast.info(e.target.tagName);
    //        LoadRequestDetails(row.mrRequest.requestID, rowIndex);
    //    }
    //};

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value
        });
    }

    function searchRequests(value) {

        console.log(requests);

        let searchMatches = [];
        var currRequest;
        var valueCleared = false;
        for (let i = 0; i < requests.length; i++) {
            currRequest = requests[i];

            if (value !== '') {
                if (currRequest.jobName.toLowerCase().includes(value.toLowerCase()) ||
                    currRequest.mrRequest.projectName.toLowerCase().includes(value.toLowerCase()) ||
                    currRequest.mrRequest.typeOfHours.toLowerCase().includes(value.toLowerCase()) ||
                    currRequest.mrRequest.requestStatus.toLowerCase().includes(value.toLowerCase()) ||
                    currRequest.agentName.toLowerCase().includes(value.toLowerCase())) {
                    searchMatches = [...searchMatches, requests[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setFilteredRequests(valueCleared ? [...requests] : [...searchMatches]);
    }

    function LoadRequestDetails(e, record) {
        Axios.get(`/api/GetRequestById`, {
            params: {
                requestID: record.mrRequest.requestID
            }
        }).then(response => {
            setState({
                ...state,
                selectedRequest: response.data,
                selectedRequestId: record.mrRequest.requestID,
                showRequestDetailsSlider: true,
                showPolicySlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleAddRequest() {
        setState({
            ...state,
            showAddRequestSlider: true,
            buttonLabel: 'Save New Request',
            modalHeader: 'Add New Request',
            requestEditMode: 'Add',
            formFields: {},
            selectedRequestId: null,
            selectedContactName: '',
            showPolicySlider: false
        });
    }

    function DownloadHoursSummaryReport(requestID, projectName, typeOfHours) {
        let myFileName = "";

        myFileName = "Hours_Summary_For_Request_Report";

        const key = "loading";

        let postdata = {
            requestID: requestID,
            projectName: projectName,
            typeOfHours: typeOfHours
        }

        message.loading({
            content: 'Report generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/DownloadHoursSummaryForRequestReport`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    async function updateTableDisplay() {
        LoadContratorRequests();
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
        });
    }

    function showPolicySlider() {
        setState({
            ...state,
            showPolicySlider: true,
            showAddRequestSlider: false
        });
    }

    function refreshUserInfo() {
        userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        setState({
            ...state,
            showPolicySlider: false,
            showAddRequestSlider: false
        });
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Contractor Market Recovery Requests</span></Card.Header>
                <Card.Body>
                    <div className="panel-hdr noTitle">
                        {userInfo.agreedtoterms === null ?
                            <Fragment>
                                <button type="button" className="btn btn-link margin-0-10" onClick={showPolicySlider}>
                                    <span style={{ backgroundColor: "#FFFF00" }}>Click Here - You must agree to our Market Recovery Policy & Guidelines before submitting any requests</span>
                                </button>
                            </Fragment>
                            : 
                            <Fragment>
                                <button type="button" className="btn btn-link margin-0-10" onClick={showPolicySlider}>
                                    View our Market Recovery Policy & Guidelines
                                </button>
                            </Fragment>
                        }
                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddRequest} disabled={userInfo.agreedtoterms === null ? true : false} >
                            Submit New Request
                        </button>
                    </div>
                    {/*<div className="row color-dark-blue">*/}
                    {/*    Specify Selection Criteria*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*    &nbsp;*/}
                    {/*</div>*/}
                    <div className="row col-lg-12">
                        <label className="form-label color-dark-blue">Specify Selection Criteria</label>
                        <Spin spinning={!state.dataLoaded}>
                            <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                <Alert
                                    message="Loading Contractor Requests"
                                    description="Please stand by while we retrieve requests for the selected contractor"
                                    type="info"
                                />
                            </div>
                            {state.dataLoaded &&
                                <Fragment>
                                    <Input.Search
                                        style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                        placeholder="Search Requests..."
                                        enterButton
                                        onSearch={searchRequests}
                                        allowClear
                                        value={state.searchValue}
                                        onChange={searchValueChanged}
                                    />
                                    {filteredRequests.length === 0 ?
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                            <span>
                                                No Market recovery Requests Found Matching Your Selection Criteria
                                            </span>
                                        } />
                                        :
                                        <div>
                                            <Table className="custom-ant-selection"
                                                rowKey={(record) => record.mrRequest.requestID}
                                                rowSelection={rowSelection}
                                                hideSelectionColumn={true}
                                                bordered
                                                size="small"
                                                dataSource={filteredRequests}
                                                style={{ whiteSpace: 'pre' }}
                                                columns={columns}
                                                pagination={{
                                                    defaultPageSize: 10,
                                                    showSizeChanger: true,
                                                    pageSizeOptions: ['10', '25', '50', '100'],
                                                    showTotal: (total, range) => (
                                                        <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                            Showing {range[0]}-{range[1]} of {total}
                                                        </span>
                                                    ),
                                                    onChange: (page, pageSize) => {
                                                        updatePageState(page, pageSize);
                                                    },
                                                    current: currentPage,
                                                    pageSize: currentPageSize
                                                }}
                                                onRow={(record, index) => {
                                                    return {
                                                        onClick: () => {
                                                            let selRows = [record.mrRequest.requestID];
                                                            setSelectedRowKeys([...selRows]);
                                                        },
                                                        onDoubleClick: (e) => {
                                                            LoadRequestDetails(e, record);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                </Fragment>
                            }
                        </Spin>
                    </div>
                    {state.showRequestDetailsSlider &&
                        <CVRequestDetailsSlider showPane={state.showRequestDetailsSlider} selectedRequestId={state.selectedRequestId} requests={state.requests} selectedContractorId={state.selectedContractorId}
                            selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showRequestDetailsSlider: false })} />
                    }
                    {state.showAddRequestSlider &&
                        <CVAddRequestSlider showPane={state.showAddRequestSlider} selectedRequestId={state.selectedRequestId} requests={state.requests} selectedContractorId={state.selectedContractorId}
                            selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showRequestDetailsSlider: false })} />
                    }

                    {state.showPolicySlider &&
                        <CVMarketRecoveryPolicySlider showPane={state.showPolicySlider} hidePane={() => setState({ ...state, showPolicySlider: false })} refreshUserInfo={refreshUserInfo}/>
                    }
                </Card.Body>
            </Card>
        </div>
    );
}