import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import ContractorTab from './ContractorTab';
import ContractorContactsTab from './ContractorContactsTab';
import ContractorRequestsTab from './ContractorRequestsTab';
import { Tabs } from 'antd';

export default function ContractorDashboard(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        selectedContractorId: '',
        selectedContractorName: '',
        formFields: {},
        contractors: [],
    });

    const { TabPane } = Tabs;

    useEffect(() => {
        setState({
            ...state,
            selectedContractorId: location.state.selectedContractorId,
            selectedContractorName: location.state.selectedContractorName,
            contractors: location.state.contractors
        });
    }, []);

    useEffect(() => {
 
    }, [props]);

    function backToSearchResults() {
        navigate("/contractor-manager", { replace: true, state: { currentPage: location.state.currentPage, currentPageSize: location.state.currentPageSize, selectedContractorId: state.selectedContractorId, filteredContractors: location.state.filteredContractors, contractors: location.state.contractors } });
    }

    return (
        <div>
            <span className="color-dark-blue hover" onClick={backToSearchResults}> <i className="fas fa-arrow-left" onClick={backToSearchResults}></i> Back to search results</span>
            <div>
                <div className="row padding-top-15 ml-3 mr-3">
                    <div className="col">
                        <h3 className="">{state.selectedContractorName}</h3>
                    </div>

                    <div className="card-container">
                        <Tabs defaultActiveKey='tab-activate-a-device' tabPosition='top' type='card'>
                            <TabPane tab='Contractor' key='tab-activate-a-device'>
                                <ContractorTab contractors={state.contractors} selectedContractorId={state.selectedContractorId} selectedIdx={state.selectedIdx} />
                            </TabPane>

                            <TabPane tab='Contacts' key='tab-manage-exhibitor-leads'>
                                <ContractorContactsTab selectedContractorId={state.selectedContractorId} />
                            </TabPane>

                            <TabPane tab='Market Recovery Requests' key='tab-reporting-tools'>
                                {/*<MRTest />*/}
                                <ContractorRequestsTab selectedContractorId={state.selectedContractorId} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}