import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useForm } from "react-hook-form";
import { Select, Spin, Alert, Empty, Table, Collapse, Input } from 'antd';
import { Fragment } from 'react';
import Axios from '../../config/axios';
import JobDetailsSlider from './JobDetailsSlider';
import { useMediaQuery } from 'react-responsive';
import { SM_SCREEN, XL_SCREEN } from '../../services/SpaService';

export default function JobManager(props) {
    const { Panel } = Collapse;

    const [state, setState] = useState({
        jobs: [],
        dataLoaded: false,
        showJobDetailsSlider: false,
        jobEditMode: 'Add',
        selectedJobIdx: null,
        selectedJob: {},
        formFields: {},
        selectedJobId: null,
        searchValue: ''
    });

    const isDesktop = useMediaQuery({ minWidth: XL_SCREEN });
    const isMobile = useMediaQuery({ maxWidth: SM_SCREEN });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [jobsListData, setJobsListData] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([]);
    const [currentPageSize, setCurrentPageSize] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setfilteredJobs] = useState([]);

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        setCurrentPage(1);
        setCurrentPageSize(10);

        setValue('formFields', {});

        setValue('formFields.jobStatus', 'Active');
        setValue('formFields.zoneRegion', 'All Zones')
        setValue('formFields.job', null)

        setState({
            ...state,
            loading: true
        });

        //console.log('about to initialize...');
        InitializeJobManager();
    }, []);

    function InitializeJobManager() {
        Axios.get(`/api/InitializeJobManager`, {
            params: {
                jobStatus: 'Active',
                zoneRegion: "All Zones",
                jobs_ID: null
            }
        }).then(response => {
            setValue("matchingJobs", response.data.matchingJobs);
            
            let jobsList = response.data.jobs;
            setJobsListData(jobsList);

            setJobs(jobsList);
            setfilteredJobs(jobsList);

            setState({
                ...state,
                loading: false,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    //function GetFilteredJobs() {
    //    Axios.get(`/api/GetFilteredJobs`, {
    //        params: {
    //            jobStatus: getValues().formFields.jobStatus,
    //            zoneRegion: getValues().formFields.zoneRegion,
    //            jobs_ID: getValues().formFields.job
    //        }
    //    }).then(response => {
    //        setValue("matchingJobs", response.data);

    //        setState({
    //            ...state,
    //            dataLoaded: true,
    //        });
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function archivedFormatter(row) {
        if (row.archived === 1) {
            return (
                <div><i id="arch" className="fa fa-check ml-3 hover" /></div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    const columns = [
        {
            title: 'jobs_ID', dataIndex: 'jobs_ID', key: 'jobs_ID', hidden: true
        }, {
            dataIndex: 'jobNum',
            title: 'Job #',
            key: 'jobNum',
            sorter: (a, b) => a.jobNum.localeCompare(b.jobNum)
        }, {
            dataIndex: 'jobName',
            title: 'Job Name',
            key: 'jobName',
            sorter: (a, b) => a.jobName.localeCompare(b.jobName)
        }, {
            dataIndex: 'address',
            title: 'Address',
            key: 'address',
            sorter: (a, b) => a.address.localeCompare(b.address),
            responsive: ['xxl']
        }, {
            dataIndex: 'city',
            title: 'City',
            key: 'city',
            width: 220,
            sorter: (a, b) => a.city.localeCompare(b.city),
            responsive: ['xxl']
        }, {
            dataIndex: 'state',
            title: 'State',
            key: 'state',
            width: 60,
            sorter: (a, b) => a.state.localeCompare(b.state),
            responsive: ['sm']
        }, {
            dataIndex: 'zip',
            title: 'Zip Code',
            key: 'zip',
            width: 130,
            sorter: (a, b) => a.zip.localeCompare(b.zip),
            responsive: ['xxl']
        }, {
            dataIndex: 'region',
            title: 'Region',
            key: 'region',
            width: 100,
            sorter: (a, b) => a.region.localeCompare(b.region),
            responsive: ['xxl']
        }, {
            title: 'Archived',
            key: 'archived',
            width: 50,
            render: (row) => archivedFormatter(row),
            align: 'center',
            responsive: ['xxl']
        }, {
            title: 'Job Details',
            key: 'jobDetails',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => LoadJobDetails(e, record)}><i id="jobDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function LoadJobDetails(e, record) {
        setState({
            ...state,
            selectedJobId: record.jobs_ID,
            showJobDetailsSlider: true
        });
    }

    function handleAddNewJob() {
        setState({
            ...state,
            selectedJobIdx: null,
            selectedJobId: null,
            showJobDetailsSlider: true
        });
    }

    //function handleReset() {
    //    setValue('formFields.jobStatus', 'Active');
    //    setValue('formFields.zoneRegion', 'All Zones')
    //    setValue('formFields.job', null)

    //    setState({
    //        ...state
    //    });

    //    GetFilteredJobs();
    //}

    //function handleSearch() {
    //    GetFilteredJobs();
    //}

    function onJobChange(value) {
        setValue('formFields.job', value)

        setState({
            ...state,
            showJobDetailsSlider: false
        });
    }

    async function updateTableDisplay(returnedJobs, idxToDirectTo = null) {
        var myPageSize = 10;
        var myPageIndex = 1;

        await setState({ ...state, loading: true });

        for (let i = 0; i < returnedJobs.length; i++) {
            if (returnedJobs[i].jobs_ID === returnedJobs[idxToDirectTo].jobs_ID) {
                myPageIndex = Math.floor(i / myPageSize) + 1
                break;
            }
        }

        setfilteredJobs(returnedJobs);

        console.log(filteredJobs);

        //setValue("matchingJobs", jobs);

        //now set the selected Job
        let selRows = [returnedJobs[idxToDirectTo].jobs_ID];
        setSelectedRowKeys([...selRows]);

        updatePageState(myPageIndex, myPageSize);

        setState({
            ...state,
            loading: false
        });
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);
    }

    function searchJobs(value) {
        console.log(value);
        console.log(jobs);

        let searchMatches = [];
        var currJob;
        var valueCleared = false;
        for (let i = 0; i < jobs.length; i++) {
            currJob = jobs[i];

            if (value !== '') {
                if ((currJob.jobNum !== null && currJob.jobNum.toLowerCase().includes(value.toLowerCase())) ||
                    (currJob.jobName !== null && currJob.jobName.toLowerCase().includes(value.toLowerCase())) ||
                    (currJob.address !== null && currJob.address.toLowerCase().includes(value.toLowerCase())) ||
                    (currJob.city !== null && currJob.city.toLowerCase().includes(value.toLowerCase())) ||
                    (currJob.state.toLowerCase().includes(value.toLowerCase()) || currJob.state.toLowerCase().includes(value.toLowerCase()))) {

                    searchMatches = [...searchMatches, jobs[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setfilteredJobs(valueCleared ? [...jobs] : [...searchMatches]);
    }

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value
        });

        searchJobs(e.target.value);
    }

    function hidePane() {
        setState({
            ...state,
            showJobDetailsSlider: false
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            JOB MANAGER
                        </h2>
                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddNewJob}>
                            Add New Job
                        </button>
                    </div>
                    <Fragment>
                        <br />
                        <div className="frame-wrap">
                            {state.loading ?
                                <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                :
                                <div className="row">
                                    <Input.Search
                                        style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                        placeholder="Search Jobs by Job #, Job Name, Address, City or State..."
                                        enterButton
                                        onSearch={searchJobs}
                                        allowClear
                                        value={state.searchValue}
                                        onChange={searchValueChanged}
                                    />
                                    {filteredJobs.length === 0 ?
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                            <span>
                                                No Jobs Found Matching Your Selection Criteria
                                            </span>
                                        } />
                                        :
                                        <Fragment>
                                            <div>
                                                <Table className="custom-ant-selection"
                                                    rowKey={(record) => record.jobs_ID}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    bordered
                                                    size="small"
                                                    dataSource={filteredJobs}
                                                    style={{ whiteSpace: 'pre' }}
                                                    columns={columns}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={!isMobile ? { left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" } : { fontWeight: 'bold' }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        ),
                                                        onChange: (page, pageSize) => {
                                                            updatePageState(page, pageSize);
                                                        },
                                                        current: currentPage,
                                                        pageSize: currentPageSize
                                                    }}
                                                    expandable={!isDesktop && {
                                                        expandedRowRender: (record) => (
                                                            <ExpandedRow record={record} />
                                                        ),
                                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                                    }}
                                                    onRow={(record, index) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.jobs_ID];
                                                                setSelectedRowKeys([...selRows]);
                                                            },
                                                            onDoubleClick: (e) => {
                                                                LoadJobDetails(e, record);
                                                            }
                                                        }
                                                    }}
                                                />

                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            }
                        </div>

                        {state.showJobDetailsSlider &&
                            <JobDetailsSlider showPane={state.showJobDetailsSlider} selectedJobId={state.selectedJobId} jobs={getValues().matchingJobs}
                                selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showJobDetailsSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}

const ExpandedRow = ({ record }) =>
(
    <div className="row">
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Address: </label>
            <span>{record.address}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">City: </label>
            <span>{record.city}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">State: </label>
            <span>{record.state}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Zip: </label>
            <span>{record.zip}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Region: </label>
            <span>{record.region}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Archived: </label>
            <span>{record.archived}</span>
        </div>
    </div>
);