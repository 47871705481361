import React, { useEffect, useState } from 'react';
//import logo from '../../img/logo.png';
import logo from '../../img/Local21Logo.png';
import spinner from '../../img/spinnerLarge.gif';
import { toast } from '@rickylandino/react-messages';
import cookie from 'react-cookies';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import { useForm, Controller } from "react-hook-form";
import '../Auth/login.css';
import NumberFormat from 'react-number-format';
import statesList from '../Helpers/StatesList';

export default function Login(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { register, getValues, setValue, control, watch } = useForm();
    const [state, setState] = useState({
        formFields: {
            
        },
        states: statesList,
        processingReg: false
    });

    useEffect(() => {
        setState({
            ...state,
            states: statesList
        });
    }, []);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function screenDataOK() {
        let message = '';
        let errorsFound = false;


        if (getValues().formFields.contactName === '' || getValues().formFields.contactTitle === '' || getValues().formFields.contactEmail === '' || getValues().formFields.contactPhone === '' || state.formFields.mainContact === undefined) {
            errorsFound = true;
        }

        if (getValues().formFields.name === '' || getValues().formFields.street1 === '' || getValues().formFields.city === '' || getValues().formFields.state === '' || getValues().formFields.zip === '') {
            errorsFound = true;
        }

        if (state.formFields.phone === '' || state.formFields.fax === '' || getValues().formFields.website === '') {
            errorsFound = true;
        }

        if (errorsFound === true) {
            message = "<strong>Mandatory fields missing - all fields are required unless otherwise noted</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        setState({
            ...state,
            processingReg: true
        });

        if (screenDataOK() === false) {
            setState({
                ...state,
                processingReg: false
            });

            return;
        }

        let postdata = getValues().formFields;
        postdata.mainContact = state.formFields.mainContact;
        postdata.phone = state.formFields.phone;
        postdata.fax = state.formFields.fax;
        postdata.mcaMember = state.formFields.mcaMember;

        await Axios.post(`/api/ProcessNewRegistration`, postdata
        ).then(response => {
            if (response.data === true) {
                toast.success("Your information was submitted successfully and an email confirmation has been sent");
            }
            else {
                toast.error("There was a problem submitted your information");
            }

            resetScreenFields();

            
        }).catch(error => {
            console.log(error);
        });
    }

    function handleMainContactChange(e) {
        setState({
            ...state,
            formFields: { ...state.formFields, mainContact: e.target.value }
        });
    }

    function handleMCAChange(e) {
        setState({
            ...state,
            formFields: { ...state.formFields, mcaMember: e.target.value }
        });
    }

    function resetScreenFields() {
        setValue('formFields.contactName', '');
        setValue('formFields.contactTitle', '');
        setValue('formFields.contactEmail', '');
        setValue('formFields.contactPhone', '');
        setValue('formFields.mainContact', '');

        setValue('formFields.companyName', '');
        setValue('formFields.street1', '');
        setValue('formFields.street2', '');
        setValue('formFields.city', '');
        setValue('formFields.state', '');
        setValue('formFields.zip', '');
        setValue('formFields.phone', '');
        setValue('formFields.fax', '');
        setValue('formFields.website', '');

        setState({
            ...state,
            processingReg: false,
            formFields: { ...state.formFields, mainContact: '', mcaMember: '', phone: '', fax: '' }
        });
    }

    return (
        <div className="background">
            <div className="signup-blankpage">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                    {/*<img src={logo} height="90px" alt="Local 21 Market Recovery" aria-roledescription="logo" />*/}
                    <img src={logo} alt="Local 21 Market Recovery" aria-roledescription="logo" />
                </div>
                <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <h3>Sign Up For A Market Recovery Account (* Indicates Required Field)</h3>
                            </div>
                            <hr style={{ border: "1px solid" }} />

                            <h5>Your Information</h5>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Name</label>
                                <input type="text" name="contactName" {...register('formFields.contactName')} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="form-label">Title</label>
                                <input type="text" name="contactTitle" {...register('formFields.contactTitle')} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Email</label>
                                <input type="text" name="contactEmail" {...register('formFields.contactEmail')} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-2">
                                <label className="form-label">Phone</label>
                                <input type="text" name="contactPhone" {...register('formFields.contactPhone')} className="form-control-custom" />
                            </div>

                            <p></p>
                            <div className="form-group col-lg-12">
                                <label className="form-label">Are you the main contact for Market Recovery at your company:</label><br />
                                <input type="radio" onChange={handleMainContactChange} name="mainContact" value="Y" className="mt-1" checked={state.formFields.mainContact === "Y"} />&nbsp;<label className="form-label">Yes</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" onChange={handleMainContactChange} name="mainContact" value="N" className="mt-1" checked={state.formFields.mainContact === "N"} />&nbsp;<label className="form-label">No</label>&nbsp;&nbsp;&nbsp;
                            </div>

                            <p></p>
                            <h5>Company Information</h5>

                            <div className="form-group col-lg-6">
                                <label className="form-label">* Company Name</label>
                                <input type="text" name="name" {...register('formFields.companyName')} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6"></div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Street 1</label>
                                <input type="text" name="street1" {...register('formFields.street1')} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Street 2 (Optional)</label>
                                <input type="text" name="street2" {...register('formFields.street2')} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-2">
                                <label className="form-label">City</label>
                                <input type="text" name="city" {...register('formFields.city')} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-1">
                                <label className="form-label">State</label>
                                <select className="form-control-custom" {...register('formFields.state')} name="state">
                                    {/*<option>NY</option>*/}
                                    {/*<option>CT</option>*/}
                                    {/*<option>MA</option>*/}
                                    {/*<option>NJ</option>*/}
                                    {/*<option>PA</option>*/}
                                    {/*<option>RI</option>*/}
                                    {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-1">
                                <label className="form-label">Zip</label>
                                <input type="text" name="zip" {...register('formFields.zip')} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-2">
                                <label className="form-label">Phone</label>
                                <NumberFormat className="form-control-custom" name="phone" value={state.formFields.phone || ''} onChange={handleInputChange} format="###-###-####" mask="_" />
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Fax</label>
                                <NumberFormat className="form-control-custom" name="fax" value={state.formFields.fax || ''} onChange={handleInputChange} format="###-###-####" mask="_" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Web Site</label>
                                <input type="text" name="website" {...register('formFields.website')} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-4"></div>

                            <div className="form-group col-lg-12">
                                <label className="form-label">MCA Member:</label><br />
                                <input type="radio" onChange={handleMCAChange} name="mcaMember" value="Y" className="mt-1" checked={state.formFields.mcaMember === "Y"} />&nbsp;<label className="form-label">Yes</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" onChange={handleMCAChange} name="mcaMember" value="N" className="mt-1" checked={state.formFields.mcaMember === "N"} />&nbsp;<label className="form-label">No</label>&nbsp;&nbsp;&nbsp;
                            </div>


                            {/*</div>*/}
                        </div>

                        <p></p>
                        <div className="form-group col-12">
                            <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit} disabled={state.processingReg}>Submit Registration</button>&nbsp;&nbsp;&nbsp;
                            <img src={spinner} alt="Local 21 Market Recovery" aria-roledescription="spinner" hidden={!state.processingReg} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}