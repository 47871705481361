import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast} from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import { useForm } from "react-hook-form";
import {  Spin, Alert, Empty, Table, Checkbox } from 'antd';
import statesList from '../Helpers/StatesList';

export default function JobDetailsSlider(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        requests: [],
        jobs: [],
        dataLoaded: false,
        selectedJobId: '',
        states: statesList
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([1]);
    const [currentPageSize, setCurrentPageSize] = useState([10]);

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (props.showPane) {
            if (props.selectedJobId !== null && props.selectedJobId !== "") {
                Axios.get(`/api/GetJobWithAssignedRequests`, {
                    params: {
                        jobs_ID: props.selectedJobId
                    }
                }).then(response => {
                    //console.log(response.data.job);

                    setValue('formFields', response.data.job);
                    setValue('formFields.jobName', response.data.job.jobName.replace('\t', ''));
                    if (response.data.job.classification === "") {
                        setValue('formFields.classification', 'Select');
                    }
                    if (response.data.job.archived === 1) {
                        setValue('formFields.archived', '1');
                    }
                    else {
                        setValue('formFields.archived', '0');
                    }

                    let assignedRequests = response.data.assignedRequests

                    setState({
                        ...state,
                        selectedJobId: props.selectedJobId,
                        dataLoaded: true,
                        jobs: props.jobs,
                        requests: assignedRequests,
                        showPane: props.showPane
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            else {
                setValue('formFields', {});
                setValue('formFields.visibility', 'B');
                setValue('formFields.status', 'A');
                setValue('formFields.archived', '0');

                setState({
                    ...state,
                    selectedJobId: null,
                    showPane: props.showPane,
                    buttonLabel: 'Add New Job',
                    jobs: props.jobs,
                    dataLoaded: true
                }, () => console.log('hello'));
            }
        }
    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    function handleSelectRequest(e, cell, row, idx) {
        const target = e.target;
        const value = target.value;

        var newList = [...state.requests];
        var itemToUpdate = newList[idx];

        itemToUpdate.selected = !itemToUpdate.selected;
        newList[idx] = itemToUpdate;

        setState({
            ...state,
            requests: newList
        });
    }

    const columns = [
        {
            title: 'requestID', dataIndex: ['mrRequest', 'requestID'], key: 'requestID', hidden: true
        }, {
            dataIndex: 'selected',
            title: 'Select',
            key: 'selected',
            width: 50,
            render: (cell, row, idx) => {
                return (
                    <>
                        <Checkbox id="chkSelect" onChange={(event) => handleSelectRequest(event, cell, row, idx)} checked={row.selected} />
                    </>
                )
            },
            align: 'center'
        }, {
            dataIndex: ['mrRequest', 'requestStatus'],
            title: 'Status',
            key: 'requestStatus',
            sorter: (a, b) => a.mrRequest.requestStatus.localeCompare(b.mrRequest.requestStatus)
        }, {
            dataIndex: 'contractorName',
            title: 'Contractor',
            key: 'contractorName',
            sorter: (a, b) => a.contractorName.localeCompare(b.contractorName)
        }, {
            dataIndex: ['mrRequest', 'projectName'],
            title: 'Project Details',
            key: 'projectName',
            render: (cell, row, idx) => (
                <div>{row.mrRequest?.projectName}<br />Type of Hours: {row.mrRequest?.typeOfHours}</div>
            ),
            sorter: (a, b) => a.mrRequest.projectName.localeCompare(b.mrRequest.projectName)
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleSubmit() {
        let formFields = getValues().formFields;

        formFields.archived = Number(getValues().formFields.archived);
        if (getValues().formFields.visibility === true) {
            formFields.visibility = 'B';
        }
        else {
            formFields.visibility = 'M';
        }
        console.log(formFields);

        if (!formFields?.jobName) {
            toast.error("Job Name is a mandatory field");
            return;
        }
        else if (!formFields?.unionStatus) {
                toast.error("Union Status is a mandatory field");
                return;
        } else {
            if (state.selectedJobId) {
                Axios.post(`/api/UpdateJob`, formFields
                ).then(response => {
                    toast.success("Job Information Successfully Updated");

                    //now update the parent list
                    //the next line basically creates kvp with an object named memberModel 
                    let jobs = state.jobs;
                    const selectedIdx = jobs.findIndex(j => j.jobs_ID === formFields.jobs_ID);
                    jobs[selectedIdx] = formFields;
                    props.updateTableDisplay(jobs, selectedIdx);

                    hidePane();
                }).catch(error => {
                    toast.error("Problem Encountered Updating Job");

                    console.log(error);
                });
            }
            else {
                Axios.post(`/api/AddNewJob`, formFields
                ).then(response => {
                    if (response.data) {
                        toast.success("New Job Successfully Added");

                        console.log(response.data);

                        //now update the parent list
                        let jobs = response.data.jobs;
                        const idxToDirectTo = jobs.findIndex(j => j.jobs_ID === response.data.newID);
                        props.updateTableDisplay(jobs, idxToDirectTo);

                        hidePane();
                    }

                }).catch(error => {
                    toast.error("Problem Encountered Adding New Job");

                    console.log(error);
                });
            }
        }
    }

    function handleGetZipCode() {
        Axios.get(`/api/GetZipCode`, {
            params: {
                address: getValues().formFields.address,
                city: getValues().formFields.city,
                state: getValues().formFields.state
            }
        }).then(response => {
            console.log(response.data);

            setValue('formFields.zip', response.data);

            setState({
                ...state,
            });
        }).catch(error => {
            toast.error("Problem retrieving zip+4");
            console.log(error);
        });
    }

    function handleUnionStatusChange(event) {
        const target = event.target;
        setValue('formFields.unionStatus', target.value);

        setState({ ...state });
    }

    function handleJobStatusChange(event) {
        const target = event.target;
        setValue('formFields.status', target.value);

        setState({ ...state });
    }

    function handleArchivedChange(event) {
        const target = event.target;
        setValue('formFields.archived', target.value);

        setState({ ...state });
    }

    function onSelectAll() {
        var newList = [...state.requests];
        newList.forEach((request, idx) => {
            request.selected = true;
        });

        setState({
            ...state,
            requests: newList
        });
    }

    function onDeselectAll() {
        var newList = [...state.requests];
        newList.forEach((request, idx) => {
            request.selected = false;
        });

        setState({
            ...state,
            requests: newList
        });
    }

    function onSetToLost() {
        var selectedRequestsCount = 0;

        var newList = [...state.requests];

        newList.forEach((request, idx) => {
            if (request.selected == true) {
                request.mrRequest.requestStatus = "Lost";

                let postdata = {
                    RequestID: request.mrRequest.requestID
                }

                //set selected request to 'Lost'
                Axios.post(`/api/SetRequestToLost`, postdata
                ).then(response => {
                    
                }).catch(error => {
                    toast.error("There was a problem setting requests to 'Lost'");
                    console.log(error);
                });

                selectedRequestsCount += 1;
            }
        });

        setState({
            ...state,
            requests: newList
        });

        if (selectedRequestsCount > 0) {
            toast.success("Selected requests set to 'Lost'");
        }
        else {
            toast.info("No requests selected to set to 'Lost'");
        }
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={props.selectedJobId ? 'Edit Existing Job Details' : 'Add New Job'}
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <>
                            <Card className="local777Card">
                                <Card.Header><span>Job Information (*Indicates required field)</span></Card.Header>
                                <Card.Body>
                                <div className="row">
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Job #</label>
                                            <input name="jobName" className="form-control-custom" {...register('formFields.jobNum')} />
                                        </div>
                                        <div className="form-group col-lg-5">
                                            <label className="form-label">* Job Name</label>
                                            <input name="jobName" className="form-control-custom" {...register('formFields.jobName')} />
                                        </div>
                                        <div className="form-group col-lg-5">
                                            <label className="form-label">Job Details</label>
                                            <input name="jobDetails" className="form-control-custom" {...register('formFields.jobDetails')} />
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Address</label>
                                            <input type="text" {...register("formFields.address")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">City</label>
                                            <input type="text" {...register("formFields.city")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-2 col-12">
                                            <label className="form-label">State</label>
                                            <select className="form-control-custom" {...register("formFields.state")}>
                                                <option></option>
                                                {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-2 col-12">
                                            <label className="form-label">Zip</label>
                                            <input type="text" {...register("formFields.zip")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label"></label>
                                            <button className="btn btn-submit form-control-custom no-border mt-2" onClick={handleGetZipCode}>Get Zip Code</button>
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <fragment>
                                                <label className="form-label">* Union Status</label><br />
                                                <span><input type="radio" name="unionStatus" value="U" checked={getValues().formFields?.unionStatus === 'U'} onChange={handleUnionStatusChange} /></span>&nbsp;Full Union
                                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="unionStatus" value="N" checked={getValues().formFields?.unionStatus === 'N'} onChange={handleUnionStatusChange} /></span>&nbsp;Full Non-Union
                                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="unionStatus" value="M" checked={getValues().formFields?.unionStatus === 'M'} onChange={handleUnionStatusChange} /></span>&nbsp;Mixed
                                            </fragment>
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Tags</label>
                                            <input type="text" {...register("formFields.tags")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Classification</label>
                                            <select className="form-control-custom" {...register("formFields.classification")}>
                                                <option>Select</option>
                                                <option>Commercial</option>
                                                <option>Educational</option>
                                                <option>Industrial</option>
                                                <option>Medical</option>
                                                <option>Residential</option>
                                                <option>Service</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Zone/Region</label>
                                            <select className="form-control-custom" {...register("formFields.region")}>
                                                <option>All Zones</option>
                                                <option>Z1</option>
                                                <option>Z2</option>
                                                <option>Z3</option>
                                                <option>Z4</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Contract Value</label>
                                            <input type="text" {...register("formFields.contractValue")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Total Value</label>
                                            <input type="text" {...register("formFields.totalValue")} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <fragment>
                                                <label className="form-label">Job Status</label><br />
                                                <span><input type="radio" name="status" value="A" checked={getValues().formFields?.status === 'A'} onChange={handleJobStatusChange} /></span>&nbsp;Active
                                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="status" value="C" checked={getValues().formFields?.status === 'C'} onChange={handleJobStatusChange} /></span>&nbsp;Completed
                                            </fragment>
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <fragment>
                                                <label className="form-label">Archived</label><br />
                                                <span><input type="radio" name="archived" value="1" checked={getValues().formFields?.archived === '1'} onChange={handleArchivedChange} /></span>&nbsp;Yes
                                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="archived" value="0" checked={getValues().formFields?.archived === '0'} onChange={handleArchivedChange} /></span>&nbsp;No
                                            </fragment>
                                        </div>
                                        <div className="form-group col-lg-8">
                                            <label className="form-label">Make Job Available in Project Profiling</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input {...register("formFields.visibility")} type="checkbox" defaultChecked={getValues().formFields?.visibility === 'B'} className="custom-control-input" />
                                                    <label className="custom-control-label"></label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group col-lg-12">
                                            <hr style={{ border: "1px solid" }} />
                                        </div>
                                        <div className="row">
                                            <div>
                                                <label className="form-label">Market Recovery Requests for this Job:</label>&nbsp;&nbsp;
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <Spin spinning={!state.dataLoaded}>
                                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                                    <Alert
                                                        message="Loading Market Recovery Requests"
                                                        description="Please stand by while we retrieve requests matching your selection criteria"
                                                        type="info"
                                                    />
                                                </div>
                                                {state.dataLoaded &&
                                                    <Fragment>
                                                        {state.requests.length === 0 ?
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                                <span>
                                                                    No Market Recovery Requests found for this job
                                                                </span>
                                                            } />
                                                            :
                                                            <div>
                                                                <Table className="custom-ant-selection"
                                                                    rowKey={(record) => record.mrRequest.requestID}
                                                                    rowSelection={rowSelection}
                                                                    hideSelectionColumn={true}
                                                                    bordered
                                                                    size="small"
                                                                    dataSource={state.requests}
                                                                    style={{ whiteSpace: 'pre' }}
                                                                    columns={columns}
                                                                    pagination={{
                                                                        defaultPageSize: 10,
                                                                        showSizeChanger: true,
                                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                                        showTotal: (total, range) => (
                                                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                                                Showing {range[0]}-{range[1]} of {total}
                                                                            </span>
                                                                        ),
                                                                        onChange: (page, pageSize) => {
                                                                            updatePageState(page, pageSize);
                                                                        },
                                                                        current: currentPage,
                                                                        pageSize: currentPageSize
                                                                    }}
                                                                    onRow={(record, index) => {
                                                                        return {
                                                                            onClick: () => {
                                                                                let selRows = [record.mrRequest.requestID];
                                                                                setSelectedRowKeys([...selRows]);
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </Fragment>
                                                }
                                            </Spin>
                                        </div>
                                        <div className="form-group col-lg-12" style={state.requests?.length === 0 ? { display: 'none' } : {}}>
                                            <span><button className="btn btn-submit btn-sm" onClick={onSelectAll}>Select All</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <span><button className="btn btn-submit btn-sm" onClick={onDeselectAll}>De-Select All</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <span><button className="btn btn-submit btn-sm" onClick={onSetToLost}>Set Selected Requests To 'Lost'</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                    </div>                  
                                </Card.Body>
                            </Card>
                        </>
                    }
                </div>
            </div>

            <div className="modal-footer pt-3">
                <div className="form-group col-12">
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit}>Save Changes</button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}