import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../config/axios';
import { Drawer, Card, message, Select, Modal } from 'antd';
import { useForm, Controller } from "react-hook-form";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { toast, confirm } from '@rickylandino/react-messages';
import { dateWithNoTimezone } from '../Helpers/DateFormat';

function ReportsSlider(props) {
    const listRef = useRef();

    const [state, setState] = useState({
        showReportCriteriaModal: false
    });

    const [modalInfo, setModalInfo] = useState({
        show: false
    });

    const [hrsSummaryModalInfo, setHrsSummaryModalInfo] = useState({
        showmodal: false,
        content: {}
    });

    const [selectCheckDateModalInfo, setSelectCheckDateModalInfo] = useState({
        showmodal: false,
        content: {}
    });

    const [height, setHeight] = useState({
        triggered: false,
        value: ''
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const [agentsListData, setAgentsListData] = useState([]);

    const [checksListData, setChecksListData] = useState([]);

    useEffect(() => {

        setTimeout(() => getListSize(), 200);

        window.addEventListener("resize", getListSize);
    }, [listRef]);

    const getListSize = () => {
        const newHeight = listRef?.current?.clientHeight;

        if (newHeight) {
            setHeight({
                triggered: true,
                value: newHeight
            });
        }
    };

    useEffect(() => {
        LoadAgents();

        if (props.showModal) {
            setModalInfo({
                show: props.showModal
            });
        }

        setState({
            ...state,
            dataLoaded: true,
        });

        setValue('formFields.selectedAgent', 'All')
        setValue('formFields.selectedAgentName', 'All');
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);
        setValue('formFields.agentrptFromDate', null);
        setValue('formFields.agentrptToDate', null);
        setValue('formFields.checkNo', null);
        setValue('formFields.contractors_ID', null);
        setValue('formFields.numWorkers', null);
        setValue('formFields.totalHours', null);
        setValue('formFields.dateRange', null);

    }, [props]);

    function LoadAgents() {
        Axios.get(`/api/GetAgents`, {
            params: {
                
            }
        }).then(response => {
            let agentsList = response.data;

            setAgentsListData(agentsList);
                        
        }).catch(error => {
            console.log(error);
        });
    }

    function handleClose() {
        setModalInfo({
            show: false
        });

        setState({
            ...state,
            showReportCriteriaModal: false
        });

        setTimeout(() => { props.hideModal(); }, 1000);
    }

    function handleReportRequest(event) {
        event.preventDefault();
        let path = '';
        switch (event.target.id) {
            case 'rptMonthlySummary':
                DownloadReport(`DownloadMonthlySummaryReport`);

                break;
            case 'rptJobPaymentSummary':
                DownloadReport(`DownloadJobPaymentSummaryReport`);

                break;
            case 'rptUnassignedRequests':
                DownloadReport(`DownloadUnassignedRequestsReport`);

                break;
            case 'rptLostJob':
                DownloadReport(`DownloadLostJobReport`);

                break;
            case 'rptAwardedLiability':
                DownloadReport(`DownloadAwardedLiabilityReport`);

                break;
            case 'rptGrantedLiabilityAll':
                DownloadReport(`DownloadGrantedLiabilityAllReport`);

                break;
            case 'rptGrantedLiabilityLowest':
                DownloadReport(`DownloadGrantedLiabilityLowestReport`);

                break;
            default:
                break;
        }
    }

    function CheckForValidDateRange() {
        //No dates entered so considered OK
        if (getValues().formFields.agentrptFromDate === null && getValues().formFields.agentrptToDate === null) {
            return true;
        }
        else if (getValues().formFields.agentrptFromDate <= getValues().formFields.agentrptToDate) {
            return true;
        }
        else {
            //this indicates either one of the date fields is null OR the From date is > To date
            return false;
        }
    }

    function DownloadReport(ApiEndpoint) {
        let myFileName = "";

        switch (ApiEndpoint) {
            case `DownloadMonthlySummaryReport`:
                myFileName = "Market_Recovery_Monthly_Summary_Report";
                break;
            case `DownloadJobPaymentSummaryReport`:
                myFileName = "Market_Recovery_Job_Payment_Summary_Report";
                break;
            case `DownloadUnassignedRequestsReport`:
                myFileName = "Market_Recovery_Unassigned_Requests_Report";
                break;
            case `DownloadLostJobReport`:
                myFileName = "Market_Recovery_Lost_Job_Report";
                break;
            case `DownloadPeachtreeCheckSummaryReport`:
                myFileName = "Market_Recovery_Peachtree_Check_Summary_Report";
                break;
            case `DownloadAwardedLiabilityReport`:
                myFileName = "Market_Recovery_Awarded_Liability_Report";
                break;
            case `DownloadGrantedLiabilityAllReport`:
                myFileName = "Market_Recovery_Granted_Liability_Report_All";
                break;
            case `DownloadGrantedLiabilityLowestReport`:
                myFileName = "Market_Recovery_Granted_Liability_Report_Lowest";
                break;
            default:
            // code block
        }

        const key = "loading";

        let postdata = {

        }

        if (ApiEndpoint === `DownloadPeachtreeCheckSummaryReport`) {
            postdata.CheckNumber = getValues().formFields.checkNo;
            postdata.Contractors_ID = getValues().formFields.contractors_ID;
        }
        else if (ApiEndpoint === `DownloadAwardedLiabilityReport` || ApiEndpoint === `DownloadGrantedLiabilityAllReport` || ApiEndpoint === `DownloadGrantedLiabilityLowestReport`) {
            var fromDate = null;
            var toDate = null;

            if (CheckForValidDateRange() === false) {
                toast.error("Invalid Report Date Range Specified");
                return;
            }

            if (getValues().formFields.agentrptFromDate !== null) {
                fromDate = Moment(dateWithNoTimezone(getValues().formFields.agentrptFromDate)).format("L");
            }
            if (getValues().formFields.agentrptToDate !== null) {
                toDate = Moment(dateWithNoTimezone(getValues().formFields.agentrptToDate)).format("L");
            }

            postdata.ReportAgent = getValues().formFields.selectedAgent.toString();
            postdata.ReportFromDate = fromDate;
            postdata.ReportToDate = toDate;
            postdata.AgentName = getValues().formFields.selectedAgentName;
        }

        message.loading({
            content: 'Report generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/` + ApiEndpoint, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    function onAgentChange(value) {
        setValue('formFields.selectedAgent', value)

        if (getValues().formFields.selectedAgent !== 'All') {
            agentsListData.forEach((agent, idx) => {
                var i = agent.values;

                if (agent.users_ID.toString() === value.toString()) {
                    setValue('formFields.selectedAgentName', agent.firstName + ' ' + agent.lastName);
                }
            });
        }
        else {
            setValue('formFields.selectedAgentName', 'All');
        }
        setState({
            ...state
        });
    }

    const getWeekEndingDateStr = (date) => {

        if (date === null) { return ""; }

        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        if (month.length == 1) { month = '0' + month; }
        if (day.length == 1) { day = '0' + day; }

        return year + month + day;
    }

    function getHoursSummaryForDateRange() {
        if (getValues().formFields?.fromDate === null || getValues().formFields?.toDate === null || (getValues().formFields?.fromDate > getValues().formFields?.toDate)) {
            toast.error('Invalid Date Range Specified');

            return;
        }

        Axios.get(`/api/GetHoursSummaryForDateRange`, {
            params: {
                weStartDate: getWeekEndingDateStr(getValues().formFields.fromDate),
                weEndDate: getWeekEndingDateStr(getValues().formFields.toDate)
            }
        }).then(response => {
            var fromDate = Moment(dateWithNoTimezone(getValues().formFields.fromDate)).format("L");
            var toDate = Moment(dateWithNoTimezone(getValues().formFields.toDate)).format("L");

            setValue('formFields.dateRange', fromDate + ' to ' + toDate);
            setValue('formFields.numWorkers', response.data.numWorkers.toLocaleString("en-US"));
            setValue('formFields.totalHours', response.data.totalHours.toLocaleString("en-US"));

            setHrsSummaryModalInfo({
                ...modalInfo,
                showmodal: true
            });
        }).catch(error => {
            console.log(error);
        });

    }

    function handlePeachtreeCheckSummary() {
        if (getValues().formFields.checkNo === null || getValues().formFields.checkNo === '') {
            toast.error("A check number must be specified");
            return;
        }

        Axios.get(`/api/GetChecksForCheckNo`, {
            params: {
                checkNo: getValues().formFields.checkNo
            }
        }).then(response => {
            let checksList = response.data;

            setChecksListData(checksList);

            //Multiple checks found for check number requested
            if (checksList.length > 1) {
                setValue('formFields.selectedContractor', "Select");

                setSelectCheckDateModalInfo({
                    ...modalInfo,
                    showmodal: true
                });

                return;
            }

            //Only one check found for the check number requested
            setValue('formFields.contractors_ID', null);
            setState({
                ...state
            });
            DownloadReport(`DownloadPeachtreeCheckSummaryReport`);

        }).catch(error => {
            console.log(error);
        });

    }

    function onRunPeachtreeReportWithDate() {
        if (getValues().formFields.selectedContractor === "Select") {
            toast.error("You must select a check date to run report");
            return;
        }

        setValue('formFields.contractors_ID', getValues().formFields.selectedContractor);
        setState({
            ...state
        });
        DownloadReport(`DownloadPeachtreeCheckSummaryReport`);
    }

    function closeHrsSummaryModal() {
        setHrsSummaryModalInfo({
            ...modalInfo,
            showmodal: false
        });
    }

    function closeSelectCheckDateModal() {
        setSelectCheckDateModalInfo({
            ...modalInfo,
            showmodal: false
        });
    }

    function onCheckSelectedChange(value) {
        setValue('formFields.selectedContractor', value);
        setState({
            ...state
        });
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Report/Output'
            placement={'right'}
            onClose={handleClose}
            visible={modalInfo.show}
            key='reportsSlider'
            width={'85%'}
            className="d-flex"
            footer={
                <div className="d-flex justify-content-between">
                    <div>
                        <button className="btn btn-outline-primary float-right" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
        >
            <div className="row align-items-center h-100">
                {!height.triggered &&
                    <div className="fixed-white-cover" />
                }
                <div className="col col-lg-6 col-12 pt-3" ref={listRef} id="getHeight">
                    <Card title="Administrator Reports" className="local777-reporting-card">
                        <p>
                            <span className="a text-decoration-underline" id="rptMonthlySummary" onClick={handleReportRequest} style={{ cursor: 'pointer' }}>Monthly Summary Report</span><br />
                            (Overview of all outstanding Market Recovery Requests)
                        </p>
                        <p>
                            <span className="a text-decoration-underline" id="rptJobPaymentSummary" onClick={handleReportRequest} style={{ cursor: 'pointer' }}>Job Payment Summary Report</span><br />
                            (What was paid and what is remaining to be paid for outstanding jobs)
                        </p>
                        <p>
                            <span className="a text-decoration-underline" id="rptPeachtreeCheckSummary" onClick={handlePeachtreeCheckSummary} style={{ cursor: 'pointer' }}>Check Summary Report</span>&nbsp;&nbsp;Enter Check No:&nbsp;<input type="text" className="inline-text-box" {...register('formFields.checkNo')} /><br />
                            (What was paid on a specific check)
                        </p>
                        <p>
                            <span className="a text-decoration-underline" id="rptLostJob" onClick={handleReportRequest} style={{ cursor: 'pointer' }}>Lost Job Report</span><br />
                            (Jobs that were Granted but Awarded to other contractors)
                        </p>
                        <p>
                            <span className="a text-decoration-underline" id="rptUnassignedRequests" onClick={handleReportRequest} style={{ cursor: 'pointer' }}>Unassigned Requests Report</span><br />
                            (Requests that have not been assigned to an Agent)
                        </p>
                        <div>
                            <span className="a text-decoration-underline" onClick={getHoursSummaryForDateRange} style={{ cursor: 'pointer' }}>Hours Summary For Date Range</span>
                            <div className="row">
                                <div className="form-group col-lg-4">
                                    From Date:&nbsp;
                                    <Controller
                                        name="formFields.fromDate"
                                        control={control}
                                        setValue={setValue}
                                        defaultValue={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                        render={() =>
                                            <DatePicker
                                                selected={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                                onChange={date => setValue('formFields.fromDate', date)}
                                                className="form-control-custom"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <NumberFormat format="##/##/####" mask="_" />
                                                } />
                                        }
                                    />
                                </div>
                                <div className="form-group col-lg-4">
                                    To Date:&nbsp;
                                    <Controller
                                        name="formFields.toDate"
                                        control={control}
                                        setValue={setValue}
                                        defaultValue={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                        render={() =>
                                            <DatePicker
                                                selected={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                                onChange={date => setValue('formFields.toDate', date)}
                                                className="form-control-custom"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <NumberFormat format="##/##/####" mask="_" />
                                                } />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                {height.triggered &&
                    <div className="col col-lg-6 col-12 pt-3" style={{ height: height.value + 'px' }}>
                        <Card title="Agent Reports" className="local777-reporting-card">
                                <div className="form-group col-lg-2">
                                    <label className="form-label">Agent</label><br />
                                    <Select
                                        {...register("formFields.selectedAgent")}
                                        size="large" placeholder="All"
                                        style={{ width: 200 }}
                                        showSearch
                                        allowClear={false}
                                        optionFilterProp="children"
                                        onChange={onAgentChange}
                                        filterOption={(input, option) =>
                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                        }
                                        value={getValues().formFields?.selectedAgent}
                                    >
                                        <option value="All">All</option>
                                        {agentsListData?.length > 0 && agentsListData?.map((agent, idx) => <option index={idx} key={agent.users_ID} value={agent.users_ID}>{agent.firstName} {agent.lastName}</option>)}
                                    </Select>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        From Date:&nbsp;
                                        <Controller
                                            name="formFields.agentrptFromDate"
                                            control={control}
                                            setValue={setValue}
                                            defaultValue={getValues().formFields?.agentrptFromDate ? Moment(getValues().formFields.agentrptFromDate).toDate() : null}
                                            render={() =>
                                                <DatePicker
                                                    selected={getValues().formFields?.agentrptFromDate ? Moment(getValues().formFields.agentrptFromDate).toDate() : null}
                                                    onChange={date => setValue('formFields.agentrptFromDate', date)}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    } />
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        To Date:&nbsp;
                                        <Controller
                                            name="formFields.agentrptToDate"
                                            control={control}
                                            setValue={setValue}
                                            defaultValue={getValues().formFields?.agentrptToDate ? Moment(getValues().formFields.agentrptToDate).toDate() : null}
                                            render={() =>
                                                <DatePicker
                                                    selected={getValues().formFields?.agentrptToDate ? Moment(getValues().formFields.agentrptToDate).toDate() : null}
                                                    onChange={date => setValue('formFields.agentrptToDate', date)}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    } />
                                            }
                                        />
                                    </div>
                                </div>
                       
                            <p>
                                <span className="a text-decoration-underline" id="rptAwardedLiability" onClick={handleReportRequest} style={{ cursor: 'pointer' }}>Awarded Liability Report</span><br />
                                (Overview of all outstanding 'Awarded' requests to show known commitments)
                            </p>
                            <p>
                                <span className="a text-decoration-underline" id="rptGrantedLiabilityAll" onClick={handleReportRequest} style={{ cursor: 'pointer' }}>Granted Liability Report (All)</span><br />
                                (This report only provides ALL Granted requests for each jobs)
                            </p>
                            <p>
                                <span className="a text-decoration-underline" id="rptGrantedLiabilityLowest" onClick={handleReportRequest} style={{ cursor: 'pointer' }}>Granted Liability Report (Lowest)</span><br />
                                (This report only provides the LOWEST Granted request for each job to show potential commitment)
                            </p>
                        </Card>
                    </div>
                }
            </div>
            <Modal
                visible={hrsSummaryModalInfo.showmodal}
                title="Hours Summary For Selected Date Range"
                onCancel={closeHrsSummaryModal}
                footer={[
                    <button className="btn btn-outline-primary" onClick={closeHrsSummaryModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="col-lg-6">
                        <label className="form-label mb-0">Date Range Reported:</label>
                    </div>
                    <div className="col-lg-6">
                        <label className="form-label mb-0 color-dark-blue">{getValues().formFields?.dateRange}</label>
                    </div>

                    <div className="col-lg-6">
                        <label className="form-label mb-0">Total Members Reported On Jobs:</label>
                    </div>
                    <div className="col-lg-6">
                        <label className="form-label mb-0 color-dark-blue">{getValues().formFields?.numWorkers}</label>
                    </div>

                    <div className="col-lg-6">
                        <label className="form-label mb-0">Total Hours Reported:</label>
                    </div>
                    <div className="col-lg-6">
                        <label className="form-label mb-0 color-dark-blue">{getValues().formFields?.totalHours}</label>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={selectCheckDateModalInfo.showmodal}
                title={"Multiple checks found for check number " + getValues().formFields?.checkNo}
                onCancel={closeSelectCheckDateModal}
                footer={[
                    <button className="btn btn-submit" onClick={onRunPeachtreeReportWithDate}>
                        Run Report
                    </button>,
                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeSelectCheckDateModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="col-lg-4">
                        <label className="form-label mb-0">Select Contractor:</label>
                    </div>
                    <div className="col-lg-8">
                        <Select
                            {...register("formFields.selectedContractor")}
                            size="large" placeholder="Select"
                            style={{ width: '100%' }}
                            showSearch
                            allowClear={false}
                            optionFilterProp="children"
                            onChange={onCheckSelectedChange}
                            filterOption={(input, option) =>
                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                            }
                            value={getValues().formFields?.selectedContractor}
                        >
                            <option value="Select">Select</option>
                            {checksListData?.length > 1 && checksListData?.map((check, idx) => <option index={idx} key={check.mrRequest.contractors_ID} value={check.mrRequest.contractors_ID}>{check.contractorName}</option>)}

                        {/*    {checksListData?.length > 1 && checksListData?.map((check, idx) => <option index={idx} key={check.mrHours.datePaid} value={check.mrHours.datePaid}>{Moment(dateWithNoTimezone(check.mrHours.datePaid)).format("L")}: {check.contractorName}</option>)}*/}
                        </Select>
                    </div>
                </div>
            </Modal>
        </Drawer>
    );
}

export default ReportsSlider;